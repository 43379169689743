@import '~ag-grid-community/src/styles/ag-grid.scss';
@import '~ag-grid-community/src/styles/ag-theme-alpine/sass/ag-theme-alpine-mixin';

// custom
.svg-inline--fa {
  pointer-events: none;
}

.ag-grid__toolbar-buttons {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: 2rem;
  margin-left: 13px;
}

.ag-grid__toolbar-button {
  box-shadow: 0.25rem 0.25rem 1rem 0 rgba(#000, 0.25);
  font-size: $font-size-small !important;
}

.action-cell-button {
  padding: 2.5px 1rem !important;
  border: 1px solid $dark-gray;
  border-radius: 0.5rem;
  background-color: $light;
  box-shadow: 0.25rem 0.25rem 1rem 0 rgba(#000, 0.25);
  // margin-right: 1rem;
  color: $dark;

  &--save,
  &--edit {
    // color: $dark;

    &:hover {
      color: $light !important;
      background-color: $success;
    }
  }

  &--cancel {
    &:hover {
      color: $light;
      background-color: $danger;
    }
  }
}

.ag-grid__multiline-cell {
  display: flex;
  flex-direction: column;
  height: auto;

  &--wrap {
    white-space: normal;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
}

.multiline-cell-editor {
  max-height: 100px;

  &__group {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin: 0 !important;
    // padding: 0 14px;
  }

  &__label {
    font-size: $font-size-small !important;
    margin: 0 !important;
  }
}

.ag-grid__list-cell-editor {
  @extend .form-control;
  font-size: $font-size-small !important;
}

.ag-grid__text-cell-editor {
  @extend .form-control;
  font-size: $font-size-small !important;
  // display: inline;
  max-width: 150px;
  border: 1px solid #ced4da !important;
  // width: auto;
}

.ag-input-field-input {
  @extend .form-control;
  font-size: $font-size-small !important;
  height: calc(1.5em + 0.5rem + 2px) !important;
  padding: 2.5px 5px !important;
}

// from template
$gridRowHoverColor: $secondary;

.ag-react-container {
  display: flex;
  align-items: center;
  height: 100%;
  width: 100%;
  overflow: hidden;
}

.ag-cell-inline-editing {
  border: 0 !important;
  background-color: inherit !important;
  padding: 0 14px !important;
}

.ag-cell--wrap,
.ag-header-cell-text {
  white-space: normal;
  overflow: wrap;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.ag-theme-alpine {
  @include ag-theme-alpine(
    (
           // row-height: ag-derived(
      font-size: 12px,
      header-height: 5rem,
      header-foreground-color: $light,
      header-background-color: $dark-gray,
           // secondary-foreground-color: ag-der
      selected-row-background-color: ag-derived(row-hover-color),
      cell-horizontal-padding: ag-derived(grid-size, $times: 1.25),
      header-column-resize-handle: true,
      header-column-resize-handle-height: 50%,
      header-column-resize-handle-width: 1px,
      header-column-resize-handle-color: ag-derived(border-color, $opacity: 0.5)
    )
  );
}

.ag-header-cell {
  background-color: ag-param(foreground-color);
  color: ag-param(background-color);
}

.status-cell-renderer {
  height: 12px;
  width: 12.01px;
  border-radius: 50%;
  display: inline-block;
  &--good {
    background-color: $green;
  }
  &--warning {
    background-color: #fbaf00;
  }
  &--danger {
    background-color: $red;
  }
}

.custom-legend {
  &__list {
    list-style: none;
  }
}
