%app-nav-dropdown {
  display: flex;
  align-items: center;
  flex-direction: row;
}

.app-navigation {
  &__dropdown {
    height: 100%;
    display: flex;
    align-items: center;
    &:hover {
      background-color: darken($light, 10%);
    }
    &--open {
      @extend %app-nav-dropdown;
      color: $light;
    }
    &--closed {
      @extend %app-nav-dropdown;
      color: $dark;
    }
    &-item {
      color: $light;
      font-size: $font-size-small;
      &:hover {
        color: darken($light, 20%);
        background-color: $menu-dropdown-color; // no change
      }
    }
  }
  &__company-nav {
    display: flex;
    align-items: center;
    height: 64px;
    justify-content: flex-end;
  }
  &__company-nav-block {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    min-width: 144px;
    &:hover {
      background-color: darken($light, 10%);
    }
  }
  &__nav-item-text {
    &--open {
      color: $light;
    }
    &--closed {
      color: $dark;
    }
    margin: 0;
    padding: 0;
    font-size: $font-size-small;
  }
  &__nav-caret-icon {
    &--open {
      color: $light;
    }
    &--closed {
      color: $dark;
    }
  }

  &__user-badge {
    display: flex;
    align-items: center;
    height: 100%;
    &:hover {
      background-color: darken($light, 10%);
    }
  }
}
