%flex-user-badge {
  display: flex;
  align-items: center;
  flex-direction: row;
}

%dropdown-user-menu {
  @extend %flex-user-badge;
  height: 100%;
  &:hover {
    background-color: darken($light, 10%);
  }
}

.user-menu {
  &__dropdown {
    &--open {
      @extend %dropdown-user-menu;
      color: $light;
    }

    &--closed {
      @extend %dropdown-user-menu;
      color: $dark;
    }

    &-item {
      color: $light;
      font-size: $font-size-small;
      &:hover {
        color: darken($light, 20%);
        background-color: $menu-dropdown-color; // no change
      }
    }
  }
  &__toggle--open {
    background-color: #d7d8d9;
  }
  &__icon-wrapper {
    @extend %flex-user-badge;
    background-color: $dark;
    border-radius: 50%;
    height: 40px;
    justify-content: center;
    padding: 1rem;
    width: 40px;
  }
  &__user-icon {
    color: $light;
    margin-left: auto;
    margin-right: auto;
  }
}
