.custom-accordion {
  .card {
    border-radius: 0;
  }
  .card-header {
    background-color: black;
    border: solid 1px black;
  }
  .card-body {
    margin: 0;
    padding: 0;
  }
}
