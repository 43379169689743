// styling overrides

textarea.form-control {
  height: $input-height;
  resize: none;
  overflow: auto;

  & + label {
    padding: 0 11px;
  }
}

textarea.custom {
  height: $input-height;
  width: 99%;
  resize: none;
  overflow: auto;
  padding: 9px 11px;

  &:focus + label,
  &:not(:placeholder-shown) + label {
    height: auto;
    width: auto;
  }

  & + label {
    padding: 0 11px;
    position: absolute;
    left: 0;
    top: 0;
    display: flex;
    align-items: center;
    height: 100%;
    width: 100%;
  }
}

.form__custom-label {
  position: absolute;
  top: -10px;
  left: 5px;
  padding: 0 3px;
  color: #000000;
  background-color: #ffffff;
  font-family: Interstate;
  font-size: 10px;
  letter-spacing: 0;
  line-height: 20px;
  z-index: 2;
}

.form-label {
  font-family: 'Interstate';
}

.form-control {
  border-radius: 0;
  padding: 11px 11px 9px 11px;
  font-family: 'Interstate' !important;
  font-size: $font-size-small;

  &[readonly] {
    background-color: #fff;
    border: 1px solid transparent;
  }
}

.form-control-plaintext {
  font-family: 'Interstate';
  font-weight: 200;
  padding: 11px 11px 9px 11px;
}

.form-check {
  padding: 0;
  // display: flex;
  // align-items: center;
  // justify-content: center;
  // flex-direction: column;

  @each $company, $color in $primary-colors {
    &-#{$company} {
      > input[type='radio'] {
        --border: $color;
        border: 1px solid $color;

        &:checked {
          --bc: $color;
        }

        &:after {
          background: $color;
        }
      }
    }
  }
}

.form-check-label {
  font-size: $font-size-small !important;
}

.form-check-input {
  height: 17px;
  width: 17px;
  background-color: #000;
}

.custom-file {
  height: $input-height;
  width: 160px;
  margin: 0;
  border: 1px solid $primary;
  border-radius: 2px;
  background: #fff;

  label {
    position: relative;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    border: 0;
    margin: 0;
    padding: 13px 20px;
    background: transparent;
    font-family: 'Forza Medium';
    font-size: 1.4rem;
    line-height: 1.4rem;
    text-decoration: none;
    text-transform: uppercase;
    white-space: nowrap;

    svg {
      color: white;
    }
  }

  &-input {
    height: $input-height;
  }

  ::after {
    content: '';
    opacity: 0;
  }
}

.custom-control-label {
  font-size: $font-size-small;
  font-family: 'Interstate';
  padding-left: 0.5rem;

  &:before {
    font-size: 16px;
    width: 16px;
    height: 16px;
    top: 0;
  }

  // &:after {
  //   height: 16px;
  //   width: 16px;
  //   top: 0;
  //   background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 8 7'%3e%3cpath fill='%23ffffff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26l2.974 2.99L8 2.193z'/%3e%3c/svg%3e") !important;
  // }
}

.custom-select {
  border-radius: 0;
  padding: 11px 11px 9px 11px;
  font-size: $font-size-small;
  background: none;
}

.custom-radio {
  display: flex;
}

@each $company in $companies {
  .custom-control-input-#{$company} {
    @extend .custom-control-input;
  }
}

@each $company, $color in $primary-colors {
  .form-check-input-#{$company} {
    --active: #{$color} !important;
    --active-inner: #{$white} !important;
    margin-right: 14px !important;
    --disabled: #{$white} !important;
    --disabled-inner: #{$color} !important;
    --border: #{$color} !important;

    &:disabled {
      --b: #{$white} !important;
      &:checked {
        --b: #{$color} !important;
        --bc: var(--border) !important;
      }
    }
  }
}

@supports (-webkit-appearance: none) or (-moz-appearance: none) {
  input[type='checkbox'],
  input[type='radio'] {
    --active: var(--primary);
    --active-inner: #{$white};
    --focus: 2px var(--accent-primary);
    --border: var(--primary);
    --border-hover: #000;
    --background: #{$white};
    --disabled: #{$white};
    --disabled-inner: #{$white};
    -webkit-appearance: none;
    -moz-appearance: none;
    height: 16px;
    outline: none;
    display: inline-block;
    vertical-align: top;
    position: relative;
    margin: 0;
    cursor: pointer;
    border: 1px solid var(--bc, var(--border));
    background: var(--b, var(--background));
    transition: background 0.3s, border-color 0.3s, box-shadow 0.2s;
    &:after {
      content: '';
      display: block;
      left: 0;
      top: 0;
      position: absolute;
      height: 12px;
      width: 9px;
      transition: transform var(--d-t, 0.3s) var(--d-t-e, ease),
        opacity var(--d-o, 0.2s);
    }
    &:checked {
      --b: var(--active);
      --bc: var(--primary);
      --d-o: 0.3s;
      --d-t: 0.6s;
      --d-t-e: cubic-bezier(0.2, 0.85, 0.32, 1.2);
    }
    &:disabled {
      --b: var(--disabled);
      cursor: not-allowed;
      opacity: 0.5;
      &:checked {
        --b: var(--primary);
        --bc: var(--border);
      }
      & + label {
        cursor: not-allowed;
        opacity: 0.5;
      }
    }
    &:hover {
      &:not(:checked) {
        &:not(:disabled) {
          --bc: var(--border-hover);
        }
      }
    }
    &:focus {
      box-shadow: 0 0 0 var(--focus);
    }
    &:not(.switch) {
      width: 16px;
      &:after {
        opacity: var(--o, 0);
      }
      &:checked {
        --o: 1;
      }
    }
    & + label {
      line-height: 16px;
      display: inline-block;
      vertical-align: top;
      cursor: pointer;
      margin-left: 9px;
      font-size: 1rem;
      font-family: 'Interstate';
    }
  }
  input[type='checkbox'] {
    &:not(.switch) {
      border-radius: 2px;
      &:after {
        width: 9px;
        height: 12px;
        border: 2px solid var(--active-inner);
        border-top: 0;
        border-left: 0;
        left: 3px;
        top: 0;
        transform: rotate(var(--r, 20deg));
      }
      &:checked {
        --r: 43deg;
      }
    }
    &.switch {
      width: 38px;
      border-radius: 11px;
      &:after {
        left: 2px;
        top: 2px;
        border-radius: 50%;
        width: 15px;
        height: 15px;
        background: var(--ab, var(--border));
        transform: translateX(var(--x, 0));
      }
      &:checked {
        --ab: var(--active-inner);
        --x: 17px;
      }
      &:disabled {
        &:not(:checked) {
          &:after {
            opacity: 0.5;
          }
        }
      }
    }
  }
  input[type='radio'] {
    border-radius: 50%;
    &:after {
      width: 14px;
      height: 14px;
      border-radius: 50%;
      background: var(--active-inner);
      opacity: 0;
      transform: scale(var(--s, 0.75));
    }
    &:checked {
      --s: 0.75;
    }
  }
}

// @each $company in $companies {
//   .custom-control-input-#{$company} {
//     @extend .custom-control-input;
//     &:checked ~ .custom-control-label::before {
//       border-radius: 0;
//       width: 1.5rem;
//       height: 1.5rem;
// @if $company == 'pointcore' {
//   background-color: $pointcore-primary;
//   border-color: $pointcore-primary;
// } @else if $company == 'core' {
//   background-color: $core-primary;
//   border-color: $core-primary;
// } @else if $company == 'procedeo' {
//   background-color: $procedeo-primary;
//   border-color: $procedeo-primary;
// } @else {
//   background-color: $primary;
//   border-color: $primary;
// }
//     }

//     ~ .custom-control-label::before {
//       width: 1.5rem;
//       height: 1.5rem;
//     }

//     ~ .custom-control-label::after {
//       width: 1.5rem;
//       height: 1.5rem;
//     }
//   }
// }
