.map-locations {
  margin-top: 37px;

  &__header {
    display: flex;
    align-items: baseline;
    padding-left: 14px;
    padding-bottom: 17px;

    > span {
      color: $gray-800;
      font-family: 'Vitesse Book';
      font-size: $h6-font-size;
      font-weight: 500;
      letter-spacing: 0;
      padding-right: 0.5rem;
    }

    > button {
      color: $gray-900;
      font-family: 'Forza Medium';
      font-size: $font-size-base;
      font-weight: 500;
      letter-spacing: 0.88px;
      padding: 0 0 0 13px;
    }
  }

  &__map {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    > span {
      height: 2.1rem;
      font-family: 'Forza Medium';
      font-size: $font-size-large;
      letter-spacing: 0;
      line-height: 2.1rem;
      margin-top: 1.5rem;
      @each $company, $color in $primary-colors {
        &--highlight-#{$company} {
          color: $color;
          font-weight: bold;
        }
      }
    }
  }

  &__text {
    font-family: 'Vitesse Book' !important;
    font-size: $font-size-medium;

    &--highlight {
      color: $core-primary;
    }
  }
}
