.ag-theme-opex {
  @include ag-theme-alpine(
    (
      border: none,
      borders: false,
      row-height: 82px,
      header-height: 82px,
      header-foreground-color: $black,
      header-background-color: $white,
    )
  );
  .ag-header-cell-label {
    font-family: 'Interstate';
    font-weight: bold;
    letter-spacing: 0;
    font-size: $font-size-small;
    text-overflow: clip;
    overflow: visible;
    white-space: normal;
  }
  .ag-header-cell {
    background-color: $light;
    border-bottom: 0.15rem solid $gray-700;
    color: $gray-800;
  }

  .ag-row {
    font-family: 'Interstate';
    color: $gray-700;
    font-size: $font-size-small;
    border-bottom: 0.1rem solid $gray-300;
  }

  .ag-cell--wrap {
    align-items: center;
    justify-content: center;
    &__content {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      margin-top: 0;
      padding-top: 2rem;
      padding-bottom: 2rem;
      line-height: 2rem;
    }
  }

  .ag-cell--multiline {
    display: block;
    display: -webkit-box;
    font-size: 1.4rem;
    line-height: 1.4rem;
    height: calc(1.4rem * 2);
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
    white-space: normal;
  }

  .ag-header-cell-label .ag-header-cell-text {
    white-space: normal !important;
  }

  .ag-details-row {
    padding: 2rem;
  }
}

.ag-theme-opex__small {
  @include ag-theme-alpine(
    (
      border: none,
      borders: false,
      row-height: 69px,
      header-height: 69px,
      header-foreground-color: $black,
      header-background-color: $white,
    )
  );
  .ag-header-cell-label {
    font-family: 'Interstate';
    font-weight: bold;
    letter-spacing: 0;
    font-size: $font-size-small;
    text-overflow: clip;
    overflow: visible;
    white-space: normal;
  }
  .ag-header-cell {
    background-color: $light;
    border-bottom: 0.15rem solid $gray-700;
    color: $gray-800;
  }

  .ag-row {
    font-family: 'Interstate';
    color: $gray-700;
    font-size: $font-size-small;
    border-bottom: 0.1rem solid $gray-300;
  }

  .ag-cell--wrap {
    align-items: center;
    justify-content: center;
    &__content {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      margin-top: 0;
      padding-top: 2rem;
      padding-bottom: 2rem;
      line-height: 2rem;
    }
  }

  .ag-header-cell-label .ag-header-cell-text {
    white-space: normal !important;
  }
}
