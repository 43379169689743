body {
  padding: 0;
}

.construction-detail-layout {
  background: $gray-100;
  min-height: 100vh;
  scroll-behavior: smooth;

  &__detail {
    width: 100%;
    padding-left: 55px;
    background: $gray-100;
    padding-right: 0px;
  }

  &__breadcrumbs {
    background: linear-gradient(to bottom,
        rgba(251, 251, 251, 1) 0%,
        rgba(251, 251, 251, 0) 100%);
    position: fixed;
    width: 100%;
    z-index: 999;
  }

  &__content {
    padding-left: 60px;
    padding-right: 60px;
    width: 100%;
    padding-top: 12px;
  }

  &__section-title {
    color: $gray-900;
    font-family: 'Vitesse Book';
    font-weight: 500;
    letter-spacing: 0;
    line-height: 36px;
  }
}
