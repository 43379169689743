%custom-legend-chart-wrapper {
  width: 100%;
  display: flex;
  justify-content: stretch;
  align-items: center;
}

.custom-legend-chart-card {
  display: flex;
  align-items: baseline;
  justify-content: stretch;
  border: none;

  &__bottom-variant {
    min-width: 280px;
    height: 620px;
    flex-direction: column;
  }
  &__side-variant {
    // max-width: 540px;
    width: 100%;
    // height: 280px;
    flex-direction: column;
    position: relative;
    &__wrapper {
      @extend %custom-legend-chart-wrapper;
      height: 350px;
      flex-direction: row;
    }
  }
  &__title {
    position: absolute;
    top: 0;
    left: 0;
    font-family: 'Vitesse Book';
    font-size: $h4-font-size;
    color: $gray-900;
    max-width: 50%;
  }
}
