.page-card {
  flex: 1;
  width: 90%;
  max-width: 100vw;
  border-radius: 6px;
  background-color: #fff;
  box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.4);
  margin: auto;
  &__row {
    display: flex;
    margin-bottom: 1.5rem;
  }
  &--regular {
    padding: 30px;
  }
  &--footer {
    padding: 0;
    padding-top: 30px;
    border: none;
    &__children {
      width: 100;
      height: contain;
      margin: {
        left: 30px;
        right: 30px;
      }
    }
  }
  &__center {
    display: flex;
    align-items: center;
    margin: auto;
    justify-content: center;
    margin-top: 40px;
  }

  &__footer {
    color: $gray-100;
    padding-top: 4rem;

    background-color: #fff;
  }

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    &-title {
      height: 24px;
      color: #1e1e1e;
      font-family: 'Vitesse Book';
      font-size: $h4-font-size;
      font-weight: 500;
      letter-spacing: 0;
      line-height: 29px;
    }
    &-subtitle {
      color: $gray-900;
      font-family: 'Forza Medium';
      font-size: $font-size-medium;
      font-weight: 500;
    }
  }
}
