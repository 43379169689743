$small: 10vw;
$medium: 20vw;
$large: 30vw;
$x-large: 40vw;

.chart {
  font-size: $font-size-small;
  color: $gray-400;
  background-color: transparent;
  // height: 100%;

  // &--small {
  //   font-size: $font-size-small * 0.75;
  //   max-width: 30rem;
  // }

  &--medium {
    margin: auto;
    height: 230px;
    width: 225px;
  }

  // &--large {
  //   max-width: 70rem;
  // }

  &__title {
    color: #626262;
    font-size: $font-size-medium;
    font-weight: 500;
    text-align: center;
    font-family: 'Vitesse Book';
  }

  &__doughnut-title {
    font-size: $font-size-xx-large;
    font-family: 'Forza Medium';
    color: $gray-900;
  }

  &__doughnut-subtitle {
    font-size: $font-size-large;
    font-family: 'Forza Medium';
    color: $gray-900;
  }

  &__doughnut-empty {
    color: $gray-500;
    font-family: 'Forza Medium';
    font-size: $font-size-medium;
    font-weight: 500;
    letter-spacing: 1.33px;
    line-height: 19px;
    text-align: center;
    // margin-left: auto;
    // margin-right: auto;
    margin: auto;
  }

  &__doughnut-wrapper {
    position: relative;
    height: 100%;
    &--vertical {
      width: 290px;
      max-height: 290px;
      margin: 20px 0;
    }
    &--horizontal {
      width: 50%;
    }
  }
  &--vertical {
    width: 100%;
  }
  &--horizontal {
    width: 50%;
    height: 100%;
  }
}
