@font-face {
  font-family: 'Forza Medium';
  src: local('Forza Medium'), url('/fonts/Forza-Medium.otf') format('opentype');
}

@font-face {
  font-family: 'Forza Bold';
  src: local('Forza Bold'), url('/fonts/Forza-Bold.otf') format('opentype');
}

@font-face {
  font-family: 'Interstate';
  src: local('Interstate Regular'),
    url('/fonts/Interstate Regular.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Interstate Bold';
  src: local('Interstate Bold'),
    url('/fonts/Interstate Bold.otf') format('opentype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Vitesse Book';
  src: local('Vitesse Book'), url('/fonts/Vitesse.otf') format('opentype');
}

@font-face {
  font-family: 'Vitesse Medium';
  src: local('Vitesse Medium'),
    url('/fonts/Vitesse-Medium.otf') format('opentype');
}

@font-face {
  font-family: 'Segoe UI';
  src: local('Segoe UI'), url('/fonts/SegoeUI.ttf') format('truetype');
}

html {
  font-size: 62.5%; /* Base 10 system for rem */
  font-family: 'Interstate', 'Segoe UI', 'Arial';
}

$font-size-base: 1rem;

$font-size-x-small: 1rem;
$font-size-small: 1.4rem;
$font-size-medium: 1.6rem;
$font-size-large: 1.8rem;
$font-size-x-large: 2rem;
$font-size-xx-large: 3.5rem;

// $h4-font-size: $font-size-large;

// base 10 font sizes

// bootstrap variables
$h1-font-size: 4.6rem;
$h2-font-size: 3.6rem;
$h3-font-size: 3rem;
$h4-font-size: 2.4rem;
$h5-font-size: 2rem;
$h6-font-size: 1.8rem;
$font-size-base: 1.4rem;
$small-font-size: 1.2rem;

// extra variables
$large-font-size: 1.6rem;
