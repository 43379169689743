.pending-change-order {
  &__table {
    width: 100%;
    thead {
      border-bottom: solid 2px $gray-700;
      th {
        color: $gray-700;
        font-family: Interstate;
        font-size: 14px;
        letter-spacing: 0;
        line-height: 60px;
        height: 60px;
        font-weight: bold;
        padding: 0 10px;
        &.money {
          text-align: right;
        }
      }
    }
    tbody,
    tfoot {
      tr {
        border-bottom: solid 1px #c1c1c2;
        color: $gray-700;
        font-family: Interstate;
        font-size: 14px;
        letter-spacing: 0;
        line-height: 60px;
        height: 60px;

        td {
          padding: 0 10px;

          &.money {
            text-align: right;
          }
        }
        // Subtotal
        &.subtotal {
          height: 50px;
          background-color: $gray-100;
          & td:first-child {
            padding-left: 20px;
          }
        }
        // Total
        &.total {
          height: 50px;
          background-color: $gray-500;
          color: $white;
          & td:first-child {
            padding-left: 20px;
          }
        }
      }
    }
    &-container {
    }
    &-title {
      font-family: 'Forza Medium';
      font-size: 17px;
    }
    &-budget-header {
      text-align: end;
    }
  }

  &__header {
    &-container {
      display: flex;
    }
    &-title {
      font-family: 'Vitesse Book';
      line-height: 29px;
      font-size: $h4-font-size;
      font-weight: 500;
      color: #1e1e1e;
    }
    &-stats {
      margin-left: 10px;
      display: flex;
      flex-direction: row;
      & > div {
        padding-left: 10px;
        padding-right: 10px;
        font-family: Interstate;
        font-size: 14px;
      }
      &-total {
        align-self: center;
      }
      &-item {
        align-self: center;
        color: $gray-800;
        font-weight: normal;
        & > span {
          border-radius: 50%;
          height: 22.44px;
          width: 22.44px;
          font-size: 14px;
          margin-right: 10px;
          &.warn {
            background-color: #ffb72c;
            color: black;
          }
          &.danger {
            background-color: $red;
            color: $white;
          }
        }
      }
    }
  }

  &__status {
    &-warn,
    &-danger {
      font-size: 14px;
      border-radius: 10px;
      min-width: 21px;
    }
    &-warn {
      background-color: #ffb72c;
      color: black;
    }
    &-danger {
      background-color: $red;
      color: $white;
    }
  }
}
