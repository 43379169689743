$grid-gutter-width: 28px;

// colors

// grays
$white: #ffffff;
$gray-100: #ededed;
$gray-200: #d8d8d8;
$gray-300: #bbbdc0;
$gray-400: #626262;
$gray-500: #4d4d4d;
$gray-600: #333333;
$gray-700: #252525;
$gray-800: #1a1a1a;
$gray-900: #191919;
$black: #000000;

// main colors
$blue: #3f84e5;
$cyan: #9fc2f2;
$teal: #44c9bc;
$green: #07a56c;
$yellow: #fad565;
$orange: #fa8334;
$red: #fa2a3f;

// aux colors
$dark-blue: #062c4f;
$dark-teal: #5fa9a2;
$dark-yellow: #ecc245;
$dark-orange: #ef7223;
$dark-red: #df382b;

//
// OPEX custom variables
//

$companies: 'pointcore', 'core', 'procedeo';

$primary: #83bf4f;
$secondary: #cee9bf;
$info: $gray-400;
$warning: $orange;
$danger: $red;
$success: $green;
$light: $white;
$dark: $gray-900;
$dark-gray: $gray-600;

$default-colors: (
  'primary': $primary,
  'secondary': $secondary,
  'success': $success,
  'info': $info,
  'warning': $warning,
  'danger': $danger,
  'light': $light,
  'dark': $dark,
) !default;
$default-primary: map-get($default-colors, 'primary');

$pointcore-colors: (
  'primary': #6ca33c,
  'secondary': #cee9bf,
  'success': $success,
  'info': $info,
  'warning': $warning,
  'danger': $danger,
  'light': $light,
  'dark': $dark,
) !default;
$pointcore-primary: map-get($pointcore-colors, 'primary');

$core-colors: (
  'primary': #008348,
  'secondary': #a5ccb7,
  'success': $success,
  'info': $info,
  'warning': $warning,
  'danger': $danger,
  'light': $light,
  'dark': $dark,
) !default;
$core-primary: map-get($core-colors, 'primary');

$procedeo-colors: (
  'primary': #00395b,
  'secondary': #fdce07,
  'success': $success,
  'info': $info,
  'warning': $warning,
  'danger': $danger,
  'light': $light,
  'dark': $dark,
) !default;
$procedeo-primary: map-get($procedeo-colors, 'primary');

$primary-colors: (
  'pointcore': $pointcore-primary,
  'core': $core-primary,
  'procedeo': $procedeo-primary,
) !default;

$breadcrumb-bg: $white;
$breadcrumb-divider-color: $white;
$breadcrumb-active-color: $white;
$breadcrumb-bg: $white;
$breadcrumb-color: $white;

$menu-dropdown-color: $gray-600;

// links

$link-color: $gray-900;
$link-hover-color: $gray-900;
$link-decoration: underline $primary solid;
$link-hover-decoration: underline $primary solid;

// forms

$label-font-family: 'Interstate';
$label-font-size: $font-size-large;

$input-font-family: 'Interstate';
$input-font-size: $font-size-large;
$input-font-weight: 200;

$input-height: 42px;

$input-border-radius: 0;

$input-color: $dark;
$input-placeholder-color: #484848;

$custom-select-indicator-color: #82888a;
$custom-select-indicator-padding: 2rem;
$custom-select-padding-x: 2.25rem;

// Bootstrap Table
// $table-cell-padding: 3.9rem;

@each $company in $companies {
  @if $company == 'pointcore' {
    :root {
      --primary: map-get($pointcore-colors, 'primary');
      --secondary: map-get($pointcore-colors, 'secondary');
      --accent-primary: adjust-color(
        map-get($pointcore-colors, 'primary'),
        $alpha: -0.5
      );
    }
  } @else if $company == 'core' {
    :root {
      --primary: map-get($core-colors, 'primary');
      --secondary: map-get($core-colors, 'secondary');
      --accent-primary: adjust-color(
        map-get($core-colors, 'primary'),
        $alpha: -0.5
      );
    }
  } @else if $company == 'procedeo' {
    :root {
      --primary: map-get($procedeo-colors, 'primary');
      --secondary: map-get($procedeo-colors, 'secondary');
      --accent-primary: adjust-color(
        map-get($procedeo-colors, 'primary'),
        $alpha: -0.5
      );
    }
  } @else {
    :root {
      --primary: map-get($default-colors, 'primary');
      --secondary: map-get($default-colors, 'secondary');
      --accent-primary: adjust-color(
        map-get($default-colors, 'primary'),
        $alpha: -0.5
      );
    }
  }
}
