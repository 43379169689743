.add-photo {
  &__container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex: 1;
    padding: 2rem 1.5rem;
    border: 1px dashed #6ca33c;
    margin: 4px 24px 0px 24px;
  }
  &__wrapper {
    margin: 0px 10px 0px 10px;
  }

  &__header {
    font-family: 'Forza Medium';
    color: #4d4d4d;
    font-size: $font-size-small;
    font-weight: 500;
    letter-spacing: 1.17px;
    line-height: $font-size-medium;
    text-align: center;
  }
  &__text {
    font-family: 'Interstate';
    color: #1a1a1a;
    font-family: Interstate;
    font-size: $font-size-medium;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 19px;
  }

  &__button {
    outline: none;
    border: none;
    background-color: white;
    text-decoration: underline;
    text-decoration-color: #6ca33c;
    padding: 0;
    margin: 0 0 1.5rem 0;
    > p {
      font-family: 'Forza Medium';
      font-size: 14px;
      margin: 0;
      color: #191919;
      font-weight: 500;
      letter-spacing: 0.88px;
      line-height: 16px;
      text-align: center;
    }
  }

  &__remove-file {
    outline: none;
    border: none;
    color: $danger;
    padding: 0.5rem;
    // border-radius: 5rem;
    background-color: $light;
  }
  &__icon {
    color: #6ca33c;
    height: 29px;
    width: 41.43px;
    padding: 3px;
  }
  &__upload-button {
    color: white;
    font-family: 'Interstate';
  }
  &__icon-button {
    margin-left: 3px;
  }
}
