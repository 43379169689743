.footer {
  margin-top: 2rem;
  width: 100%;
  background: $dark;
  color: $light;
  padding: 2rem;
  font-size: $font-size-small;

  &--fixed {
    position: fixed;
    bottom: 0;
    right: 0;
    left: 0;
    margin-top: auto;
  }
}
