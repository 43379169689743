%list-accordion__full-size {
  height: 100%;
  width: 100%;
}

.list-accordion {
  &__card-wrapper {
    border-radius: 0px;
    display: flex;
    justify-content: center;
  }
  &__toggle {
    @extend %list-accordion__full-size;
    color: $light;
    display: flex;
    justify-content: space-between;
  }
  &__toggle-text {
    margin: 0 5px;
  }
  &__list {
    @extend %list-accordion__full-size;
    margin: 0;
    padding: 0;
  }
}
