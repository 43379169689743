.ag-theme-opex-p1 {
  .ag-header-cell-label {
    font-family: Interstate;
    font-weight: bold;
    letter-spacing: 0;
    font-size: $font-size-small;
    text-overflow: clip;
    overflow: visible;
    white-space: normal;
  }

  .header-center .ag-header-cell-label {
    justify-content: center;
  }

  .header-right .ag-header-cell-label {
    justify-content: flex-end;
    text-align: right;
  }

  .ag-header-cell {
    background-color: $light;
    border-bottom: 0.15rem solid $gray-700;
    color: $gray-800;
  }

  .ag-row {
    font-family: Interstate;
    color: $gray-700;
    font-size: $font-size-small;
    border-bottom: 0.1rem solid $gray-300;

    .ag-cell-value {
      .indicator-container {
        width: 100%;
        height: 20px;
        padding-top: 1rem;

        .indicator {
          height: 10px;
          width: 10px;
          margin: 0 auto;
          border-radius: 5px;

          &.safe {
            background-color: $green;
          }
          &.danger {
            background-color: $red;
          }
          &.alert {
            background-color: $yellow;
          }
        }
      }
    }
  }

  .ag-cell.cell-center {
    text-align: center;
  }

  .ag-cell.cell-right {
    text-align: right;
  }

  .ag-cell--wrap {
    align-items: center;
    justify-content: center;
    &__content {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      margin-top: 0;
      padding-top: 2rem;
      padding-bottom: 2rem;
      line-height: 2rem;
    }

    .indicator-container {
      width: 100%;
      height: 20px;
      padding-top: 1rem;

      .indicator {
        height: 10px;
        width: 10px;
        margin: 0 auto;
        border-radius: 5px;
        &.safe {
          background-color: $green;
        }
        &.danger {
          background-color: $red;
        }
        &.alert {
          background-color: $yellow;
        }
      }
    }
  }

  .ag-header-cell-label .ag-header-cell-text {
    white-space: normal !important;
  }

  @include ag-theme-alpine(
    (
      border: none,
      borders: false,
      row-height: 82px,
      header-height: 82px,
      header-foreground-color: $black,
      header-background-color: $white,
    )
  );
}

.ag-theme-opex-p1__small {
  @include ag-theme-alpine(
    (
      border: none,
      borders: false,
      row-height: 69px,
      header-height: 69px,
      header-foreground-color: $black,
      header-background-color: $white,
    )
  );
  .ag-header-cell-label {
    font-family: Interstate;
    font-weight: bold;
    letter-spacing: 0;
    font-size: $font-size-small;
    text-overflow: clip;
    overflow: visible;
    white-space: normal;
  }
  .ag-header-cell {
    background-color: $light;
    border-bottom: 0.15rem solid $gray-700;
    color: $gray-800;
  }

  .ag-row {
    font-family: Interstate;
    color: $gray-700;
    font-size: $font-size-small;
    border-bottom: 0.1rem solid $gray-300;
  }

  .ag-header-cell-label .ag-header-cell-text {
    white-space: normal !important;
  }
}
