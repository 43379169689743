.section-card {
  &__container {
    // min-height: 523px;
    height: fit-content;
    min-width: 620px;
    width: 100%;
    border-radius: 6px;
    background-color: #ffffff;
    box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.4);
  }

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 31px 26px 23px 26px;
  }

  &__title {
    color: #1e1e1e;
    font-family: 'Vitesse Book';
    font-size: 24px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 29px;
    white-space: nowrap;
  }

  &__action-component {
    min-height: 40px;
    display: flex;
    width: 76%;
    justify-content: flex-end;
    align-items: center;
  }

  &__body {
    margin: 0px 26px 40px 26px;
    height: 100%;
  }
}
