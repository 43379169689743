%fixed-margin-settings {
  margin: {
    right: auto;
    left: auto;
    bottom: 38px;
  }
}

.pointcore-dashboard-layout {
  padding-bottom: 80px; // give space below page card
  &__title {
    color: $white;
    font-family: 'Vitesse Book';
    font-weight: 500;
    letter-spacing: 0;
    line-height: 36px;
    margin: 0;
  }
  &__row {
    @extend %fixed-margin-settings;
  }
  &__section-wrapper {
    width: 88%;
    @extend %fixed-margin-settings;
  }

  &__summary-title {
    color: $gray-900;
    font-family: 'Vitesse Book';
    font-weight: 500;
    margin: 0;
    padding-bottom: 15px;
    letter-spacing: 0;
    line-height: 29px;
  }
}
