@import 'base/reset';

@import 'base/typography';
@import 'base/pointer';

@import 'abstracts/variables';
@import 'abstracts/mixins';
@import 'abstracts/functions';

@import 'vendors/bootstrap';
@import 'vendors/react-image-lightbox';
@import 'vendors/fontawesome';
@import 'vendors/ag-grid';
@import 'vendors/datepicker';
@import 'vendors/nprogress';
@import '~mapbox-gl/dist/mapbox-gl.css';

@import 'components/UI';
@import 'components/POINTCORE';
@import 'components/CORE';
@import 'components/ConstructionDetail';
@import 'components/settings/';

@import 'pages/construction-detail';
@import 'pages/login';
@import 'pages/pointcore-precon-detail';
