.schedule-detail {
  &__split {
    flex: 1;
    justify-content: center;
    align-items: center;
  }
  &__cip {
    color: #008348;
    font-family: 'Forza Medium';
    font-size: 46px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 46px;
    text-align: center;
  }
  &__title {
    color: $gray-400;
    font-family: 'Vitesse Book';
    font-size: $font-size-medium;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 19px;
    text-align: center;
  }
  &__main {
    color: $gray-900;
    font-family: 'Forza Medium';
    font-size: $font-size-x-large;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 22px;
    text-align: center;
  }
  &__subtitle {
    color: #9c9c9c;
    font-family: Interstate;
    font-size: $font-size-small;
    letter-spacing: 0;
    text-align: center;
    line-height: 24px;
  }
}
