.detail-observations {
  &__vertical-wrapper {
    width: 290px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }

  &__title {
    color: $gray-800;
    font-family: 'Vitesse Book';
    font-size: 18px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 22px;
  }

  &__title__small {
    color: #9c9c9c;
    font-family: 'Forza Medium';
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 1.33px;
    line-height: 19px;
    margin-left: 15px;
  }

  &__inspection-states {
    color: $gray-500;
    font-family: Interstate;
    font-size: $font-size-small;
    letter-spacing: 0;
    line-height: 24px;
  }

  &__progress-bar {
    border-radius: 1rem;
    height: 16px;
    & > div {
      height: 16px;
    }
  }

  &__table {
    width: 100%;
    margin-top: 20px;
    & > tr {
      & > td {
        border-top: none;
        color: $gray-700;
        font-family: Interstate;
        font-size: $font-size-medium;
        letter-spacing: 0;
        line-height: 24px;
      }
    }
    &__header {
      box-sizing: border-box;
      width: 100%;
      border-bottom: 1.5px solid $gray-900;
      padding: 15px 15px;
      & > th {
        font-weight: bold;
        font-size: 14px;
        font-family: Interstate, sans-serif;
        line-height: 17px;
      }
    }
    &__row {
      &--border {
        border-bottom: 1px solid $gray-300;
      }
      box-sizing: border-box;
      width: 100%;
      height: 80px;
      & > td {
        margin: 1rem;
      }
      &__toggle {
        &--open {
          color: $gray-600;
        }
        &--closed {
          background-color: $white;
        }
      }
      &__col {
        display: flex;
        margin: 2rem 1rem;
        min-height: 70px;
        justify-content: flex-start;
        align-items: center;
      }
      &__section {
        & > td {
          flex: 1;
          display: flex;
          justify-content: flex-start;
          align-items: center;
        }
        box-sizing: border-box;
        width: 100%;
        flex: 1;
        display: flex;
        justify-content: stretch;
        align-items: center;
        flex-direction: row;
      }
      &__dropdown {
        width: 100%;
        box-sizing: border-box;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 10px;
        &__element {
          width: 100%;
          min-height: 80px;
          margin: 1rem;
        }
      }
    }
  }

  &__inspector {
    &__name {
      color: $gray-700;
      font-family: Interstate;
      font-size: 16px;
      letter-spacing: 0;
      line-height: 24px;
      padding: 0;
      margin-left: 1rem;
    }
  }
}
