.rating-control {
  @each $company, $color in $primary-colors {
    &-#{$company} {
      &__btn {
        outline: none;
        background-color: inherit;
        border: none;
        padding: 0;
        text-decoration: none;
        &:focus {
          outline: none;
        }
        &__icon {
          &--unfilled {
            color: $gray-300;
          }
          &--filled {
            color: $color;
          }
        }
      }
    }
  }
}
