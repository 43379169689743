.client-selector {
  flex-wrap: nowrap;
  margin-right: 0;
  margin-left: 0;
  display: flex;
}

.client-selector__label {
  display: flex;
  align-items: center;
  color: $white;
  font-family: 'Interstate';
  font-size: $large-font-size;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 19px;
}

.client-selector__col {
  & + .client-selector__col {
    padding-left: 14px;
  }
}

.client-selector__button {
  box-sizing: border-box;
  padding: 9px 20px;
  border-radius: 6px;
  box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.4);
  border: 1px solid $gray-500;
  background-color: $white;
  opacity: 0.7;

  &--selected,
  &:hover {
    border: 1.5px solid $primary;
    background-color: $primary;
    opacity: 1;

    > span {
      color: $white !important;
    }
  }

  > span {
    color: $gray-500;
    font-family: 'Forza Medium';
    font-size: $large-font-size;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 19px;
    text-align: center;
  }
}
