.legend-header {
  margin-top: 10px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  &__header {
    border-bottom: 1px solid $gray-300;
    width: 100%;
    margin-top: 27px;
    &__text {
      color: $gray-500;
      font-size: $font-size-medium;
      font-family: 'Vitesse Book';
      font-weight: 500;
      letter-spacing: 0;
      line-height: 19px;
      padding-bottom: 18px;
    }
  }
}
