.login {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;

  &__container {
    display: flex;
    flex-direction: column;
    background-color: $white;
    box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.4);
    width: 404px;
    padding: 40px 30px 32px 30px;
    border-radius: 6px;
    font-size: 16px;
  }

  &__logo {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__title {
    font-family: 'Vitesse Book';
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 24px;
    line-height: 22px;
    color: #212121;
  }

  &__forgot-password {
    font-family: 'Forza Medium';
    font-size: 14px;
    font-weight: 500;
    color: $dark;
    text-decoration-color: $default-primary;
    text-decoration-line: underline;
    @extend .p-0;
  }

  input[type='email'],
  input[type='password'] {
    border: 1px solid $gray-500;
    border-radius: 2px;
    box-sizing: border-box;
    height: 42px;
    font-size: $font-size-small;
  }

  .form-control {
    color: $gray-800;
  }

  /* Variations */
  &-core {
    background-image: url('/core-login.jpg');
    background-size: cover;
  }

  &-pointcore {
    background-image: url('/pointcore-login.jpg');
    background-size: cover;
  }

  &-procedeo {
    background-image: url('/construction_bg3.jpg');
    background-size: cover;
  }
}
