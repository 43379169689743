.custom-tooltip {
  &__wrapper {
    min-height: 159px;
    width: 320px;
    border-radius: 2px;
    background-color: $white;
    box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.4);
    border: none;
    padding: 15px;
    position: absolute;
    z-index: 1;
    &-percent {
      min-height: 132px;
      width: 244px;
      border-radius: 2px;
      background-color: $white;
      box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.4);
      border: none;
      padding: 15px;
      position: absolute;
    }
  }
  &__title {
    border-bottom: 1px solid $gray-300;
    background-color: $white;
    color: $gray-400;
    font-family: 'Vitesse Book';
    font-size: $font-size-medium;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 19px;
    padding: 0;
    padding-bottom: 12px;
    &__status {
      display: inline-block;
      margin-left: 8px;
      color: $gray-400;
      font-family: 'Vitesse Book';
      font-size: 16px;
      font-weight: 500;
      letter-spacing: 0;
    }
    &__count {
      color: $gray-900;
      font-family: 'Forza Medium';
      font-size: 14px;
      font-weight: 500;
      letter-spacing: 1.17px;
      line-height: 16px;
    }
  }
  &__body {
    height: 90%;
    padding: 7.5px 0;
    &__title {
      color: $gray-900;
      font-family: Interstate;
      font-size: 14px;
      font-weight: bold;
      letter-spacing: 0;
      line-height: 18px;
    }
    &__divs {
      color: $gray-400;
      font-family: Interstate;
      font-size: 14px;
      letter-spacing: 0;
      line-height: 18px;
    }
  }
  &__list {
    width: 100%;
    list-style: none;
    & > li {
      margin: 10px 0;
      width: 100%;
    }
  }
  &__list-item {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    &__name {
      color: $gray-400;
      font-family: Interstate;
      font-size: $font-size-base;
      letter-spacing: 0;
      line-height: 18px;
      word-wrap: normal;
    }
    &__value {
      color: $gray-900;
      font-family: Interstate;
      font-size: $font-size-base;
      font-weight: bold;
      letter-spacing: 0;
      line-height: 18px;
      text-align: right;
    }
  }
}
