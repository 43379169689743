@each $company in $companies {
  .card-#{$company} {
    @extend .card;
  }

  .card-#{$company}-body {
    @extend .card-body;
  }

  .card-#{$company}-title {
    @extend .card-title;
  }

  .card-#{$company}-subtitle {
    @extend .card-subtitle;
  }

  .card-#{$company}-text {
    @extend .card-text;
  }

  .card-#{$company}-text:last-child {
    margin-bottom: 0;
  }

  .card-#{$company}-link {
    @extend .card-link;
  }

  .card-#{$company}-header {
    @extend .card-header;
  }

  .card-#{$company}-footer {
    @extend .card-footer;
  }

  .card-#{$company}-header-tabs {
    @extend .card-header-tabs;
  }

  .card-#{$company}-header-pills {
    @extend .card-header-pills;
  }

  .card-#{$company}-img-overlay {
    @extend .card-img-overlay;
  }

  .card-#{$company}-img,
  .card-#{$company}-img-top,
  .card-#{$company}-img-bottom {
    width: 100%; // Required because we use flexbox and this inherently applies align-self: stretch
  }

  .card-#{$company}-img,
  .card-#{$company}-img-top {
    @include border-top-radius($card-inner-border-radius);
  }

  .card-#{$company}-img,
  .card-#{$company}-img-bottom {
    @include border-bottom-radius($card-inner-border-radius);
  }

  .card-#{$company}-group {
    @extend .card-group;
  }

  .accordion-#{$company} {
    > .card-#{$company} {
      overflow: hidden;

      &:not(:last-of-type) {
        border-bottom: 0;
        @include border-bottom-radius(0);
      }

      &:not(:first-of-type) {
        @include border-top-radius(0);
      }

      > .card-header {
        @include border-radius(0);
        margin-bottom: -$card-border-width;
      }
    }
  }

  $colors: company-colors($company);

  // Generate `.bg-*` color utilities
  @each $color, $value in $colors {
    @include bg-variant('.bg-#{$company}-#{$color}', $value);
  }
}
