html,
body {
  scroll-behavior: smooth; // easy css-method for making smooth href transitions
  // look into https://stackoverflow.com/a/25036794 for JS-based solution if more speed customization
  //  is needed
}

.overview-jump {
  width: 54px;
  height: 100%;
  position: absolute;
  left: 0;
  top: 120px;
  overflow: hidden;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  background-color: $gray-600;
  &__nav-item {
    background-color: $gray-600;
    width: 100%;
    height: 46px;
    &:hover {
      opacity: 0.5;
    }
    &__link {
      padding: 0;
      height: 100%;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      &__icon {
        width: 24px;
        height: auto;
        color: $white;
      }
    }

    @each $company, $color in $primary-colors {
      &-#{$company} {
        &.active {
          background-color: $gray-900;
          border-left: 6px solid $color;
          // Invisible right border to keep icon centered
          border-right: 6px solid $gray-900;
          border-radius: 0;
        }
      }
    }
  }
}

.nav-link {
  background-color: $gray-600;
  @each $company, $color in $primary-colors {
    &-#{$company} {
      &.active {
        background-color: $gray-900;
        border-left: 6px solid $color;
        // Invisible right border to keep icon centered
        border-right: 6px solid $gray-900;
        border-radius: 0;
      }
    }
  }
}

.blink_me {
  animation: blinker 50ms linear;
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}
