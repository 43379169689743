%flex {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: nowrap;
}

%ellipsis {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.project-nav {
  @extend %flex;
  background-color: $dark;
  width: 100%;
  height: 58px;
  z-index: 900 !important;
  margin: 0;
  padding: 0;
  border: 0;
  font-family: 'Forza Medium';
  font-size: 1.4rem;
  color: $light;

  &__wrapper {
    @extend %flex;
    width: 100%;
    justify-content: space-between;
  }

  @at-root .project-info {
    display: flex;
    flex-direction: row;
    align-items: center;
    color: $white;
    background-color: transparent;
    border: 0;

    &__left-text {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding-right: 30px;
    }

    &__separator {
      height: 30px;
      width: 1px;
      background-color: $gray-300;
      opacity: 0.7;
    }

    > span {
      padding-left: 30px;
    }

    &__name,
    &__amount {
      @extend %ellipsis;
      font-family: 'Forza Medium';
      letter-spacing: 1px;
      line-height: 20px;
    }

    &__contract {
      font-family: 'Interstate';
      font-size: $small-font-size;
    }
  }
}
