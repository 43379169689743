.detail-card {
  border-radius: 6px;
  background-color: $light;
  box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.4);
  padding: 22px 0;

  &__inline {
    &:first-child {
      padding-left: 0;
    }
    &:last-child {
      padding-right: 0;
    }
    margin: 0;
  }
  &--no-shadow {
    box-shadow: none !important;
    border-radius: 0;
    background-color: $light;
    padding: 22px 0;
  }
  &--small {
    height: 100%;
    width: 100%;
    min-height: 153px;
    margin-left: auto;
    margin-right: auto;
  }

  &--small-overview {
    margin: auto;
    margin-top: auto;
    min-height: 135px;
    margin-bottom: auto;
    height: 100%;
    min-width: 296px;
    width: 100%;
    padding: 22px 0;
    &-set {
      height: 171px;
      min-height: 135px;
      min-width: 296px;
      width: 100%;
      padding: 22px 0;
    }
  }

  &--half-width {
    margin: auto;
    margin-top: auto;
    margin-bottom: auto;
    height: 100%;
    width: 50%;
  }

  &--full-width {
    &-b {
      height: 100%;
      margin: auto;
      margin-top: auto;
      margin-bottom: 37px;
      min-height: 113px;
      width: 100%;
    }
    height: 100%;
    margin: auto;
    margin-top: auto;
    min-height: 113px;
    width: 100%;
  }

  &--large {
    height: 100%;
    margin-left: 28px;
    margin-right: auto;
    margin-top: auto;
    margin-bottom: 28px;
    width: 100%;
  }

  &__title-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__title {
    color: $gray-400;
    font-family: 'Vitesse Book';
    font-weight: 500;
    letter-spacing: 0;
    line-height: 22px;
    text-align: center;
    &--small {
      font-size: $font-size-medium;
    }
  }

  &__sections-wrapper {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: row;
    margin: auto;
    justify-content: space-between;
    align-items: center;
    overflow-y: hidden;
  }

  &__separator {
    height: 90%;
    border-left: 1px solid $gray-300;
    width: 1px;
  }
}
