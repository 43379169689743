.company-settings {
  &__loading {
    &_container {
      text-align: center;
      font-size: 6rem;
      padding: 6rem 0;
      color: $primary;
    }

    &_title {
      padding: 2rem 2rem 0.5rem 2rem;
      color: $gray-800;
      font-family: 'Vitesse Book';
      font-size: $h4-font-size;
      font-weight: 500;
    }
  }
  &__page {
    &_grid {
      margin-bottom: 2.8rem;

      .collapse {
        //padding: 1rem 3rem 2.5rem 3rem;
      }
    }
    &_container {
      flex: 1 1;
      width: 90%;
      max-width: 100vw;
      margin: auto;
    }
  }
  &__grid {
    &_container {
      .expandable-card__header-content {
        width: 100%;
      }
      &.expandable {
        .company-settings_expandable-grid {
          margin-bottom: 2.5rem;
        }
      }
      .expandable-card__body {
        padding: 1rem 3rem 2.5rem 3rem;
      }

      .ag-cell-focus,
      .ag-cell {
        border: none !important;
      }
      .ag-theme-opex .ag-row-selected {
        background-color: white;
      }
      .ag-row-hover {
        background-color: white;
      }
    }
    &_card {
      &_title {
        display: flex;
        justify-content: space-between;
        width: 100%;
        padding-right: 3rem;

        &_text {
          color: $gray-800;
          font-family: 'Vitesse Book';
          font-size: $h4-font-size;
          font-weight: 500;
        }
        &_button {
          button {
            margin-left: 1.5rem;

            svg {
              color: $primary;
            }
          }
        }
      }
      &_toggle_button {
        font-size: $font-size-x-large;
        background-color: white;
        border: none;
        color: $gray-700;
        padding: 0 1.6rem 0 0;

        &:focus {
          outline: 0;
        }
        &:disabled {
          color: $white;
        }
      }
    }
  }
  &_expandable-grid {
    &_header {
      border-bottom: 1.5px solid $gray-800;
      display: table;
      width: 100%;
      padding: 2rem 3rem 2rem 3.5rem;
      div {
        color: $gray-800;
        font-family: Interstate;
        font-size: $font-size-small;
        font-weight: bold;
        line-height: $font-size-large;
        display: table-cell;
      }

      .region {
        width: 20%;
      }
      .vista {
        width: 65%;
      }
      .manage {
        width: 14%;
      }
    }
    &_row {
      border: 0 !important;
      .card-header {
        background-color: $white;
        padding: 2rem 0;
        border-bottom: 2px solid $gray-300;
        transition: border 0.3s ease-in-out;
        display: table;
        width: 100%;
        &.expanded {
          transition: border 0.35s ease-in-out;
          border-bottom: 1px solid $gray-300;
        }

        div {
          color: $gray-700;
          font-family: Interstate;
          font-size: $font-size-medium;
          line-height: $h4-font-size;
          display: table-cell;
        }
        .button {
          width: 1%;
        }
        .region {
          width: 12%;
        }
        .vista {
          width: 39%;
        }
        .manage {
          width: 10%;
        }
      }

      .card-body {
        border-top: 1px solid $gray-300;
        border-bottom: 1px solid $gray-300;
        padding: 0;
      }
      &_body {
        &_container {
          display: table;
          width: 100%;
          margin-left: 5.5rem;
          border-top: 1px solid $gray-300;

          &.first {
            border-top: none;
          }
        }
        &_item {
          padding: 2rem 0;
          display: table-cell;
          color: $gray-700;
          font-family: Interstate;
          font-size: $font-size-medium;
          line-height: $h4-font-size;

          &.program {
            width: 19%;
          }
          &.vista {
            width: 19%;
          }
          &.contact {
            width: 43%;
          }
          &.manage {
            width: 18%;
            /*button {
              margin-left: 2rem;
            }*/
          }
        }
      }
    }

    &.pointcore {
      .company-settings_expandable-grid_header {
        .region {
          width: 20%;
        }
        .vista {
          width: 20%;
        }
        .contract {
          width: 45%;
        }
        .manage {
          width: 14%;
        }
      }

      .company-settings_expandable-grid_row {
        .card-header {
          .button {
            width: 1%;
          }
          .region {
            width: 12%;
          }
          .vista {
            width: 39%;
          }
          .manage {
            width: 10.5%;
          }
        }
      }
      .company-settings_expandable-grid_row_body {
        &_item {
          //company-settings_expandable-grid_row_body_item program
          &.program {
            width: 19%;
          }
          &.vista {
            width: 19%;
          }
          &.contact {
            width: 43%;
          }
          &.manage {
            width: 19%;
            /* button {
              margin-left: 2rem;
            }*/
          }
        }
      }
    }
  }
  &__region {
    &_table {
      width: 100%;
      margin-bottom: 2.5rem;

      thead {
        color: $gray-800;
        font-family: Interstate;
        font-size: $font-size-small;
        font-weight: bold;
        line-height: $font-size-large;
        border-bottom: 1.5px solid $gray-800;

        .region {
          padding: 2rem 0rem 2rem 3.5rem;
          width: 20%;
        }
        .vista {
          width: 65%;
          padding: 2rem 0rem;
        }
        .manage {
          width: 15%;
          padding: 2rem 3rem 2rem 0rem;
        }
      }
      tbody {
        tr {
          border-bottom: 1px solid $gray-300;
        }
        td {
          padding: 2rem 0;
          color: $gray-700;
          font-family: Interstate;
          font-size: $font-size-medium;
          line-height: $h4-font-size;
        }
        .region {
          padding: 2rem 0rem 2rem 3.5rem;
          width: 20%;
        }
        .vista {
          width: 65%;
          padding: 2rem 0rem;
        }
        .manage {
          width: 15%;
          padding: 2rem 3rem 2rem 0rem;
        }
      }
    }
  }
  &__no_data {
    text-align: center;
    padding: 2rem 0;
    color: $gray-700;
    font-family: Interstate;
    font-size: $font-size-medium;
    line-height: $h4-font-size;
    border-bottom: 1px solid $gray-300;
  }
  &__row_action_button {
    &:hover {
      color: $primary;
    }
  }
}
