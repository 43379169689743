.process-checklist {
  color: $gray-900;

  &__expandable_container {
    // .expandable-card__body {
    //   //border-top: solid 1.5px $gray-900;
    //   //margin-top: 15px;
    // }
    .expandable-card__container {
      padding: 0;
    }
    .expandable-card__header {
      padding: 2.78rem 2.36rem;
    }
    .expandable-card__header-icon {
      height: 30px;
      text-align: center;
      width: 2.7rem;
    }
  }

  &__expandable_separator {
    height: 1.5px;
    margin: 0 2.36rem;

    &.populated {
      background-color: $gray-900;
    }
    &.empty {
      background-color: $gray-300;
    }
  }

  &__no_data {
    color: $gray-500;
    font-family: 'Forza Medium';
    font-size: $font-size-medium;
    font-weight: 500;
    letter-spacing: 1.33px;
    line-height: $font-size-large;
    margin-bottom: 6rem;
    text-align: center;
  }

  &__header {
    padding: 0 2.36rem;
    &_content {
      font-size: $h4-font-size;
      font-family: 'Vitesse Book';
      line-height: 2.9rem;
      padding: 0 0rem 2.78rem 0;
      font-weight: bold;
      border-bottom: solid 1.5px $gray-900;

      &_component {
        font-size: $h4-font-size;
        font-family: 'Vitesse Book';
        line-height: 2.9rem;
        margin-bottom: 2rem;
      }
    }
  }
  &__card_header {
    &_container {
      display: flex;
      justify-content: space-between;
    }
    &_s1 {
      display: flex;
      width: 80%;
    }
    &_title {
      width: 30%;
    }
    &_tasks {
      border-left: 1px solid $gray-300;
      padding-left: 3rem;
      font-family: 'Forza Medium';
      font-size: $font-size-medium;
      letter-spacing: 1.33px;
      font-weight: 500;

      span {
        margin-right: 1.5rem;
      }
    }
    &_indicator {
      color: #333;
    }
  }
  &__container {
    /*padding: 2.78rem 0;
    box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.4);
    border-radius: 6px;*/
    border: none;
    padding: 0 23px 0 23px;

    .close-accordion.accordion {
      .card-pointcore {
        opacity: 1;
        transition: all 0.3s ease-in-out;
      }
    }
    .open-accordion.accordion {
      .card-pointcore {
        opacity: 0.5;
        transition: all 0.3s ease-in-out;

        .process-checklist__subtask_container .card-pointcore {
          opacity: 1;
        }
      }
    }
    .process-checklist__card_header.card-header {
      background-color: $white;
      padding: 2rem 0;
      margin: 0 1rem;
      border-bottom: none;
    }

    .card-body {
      padding: 0 2.36rem 3.25rem 2.36rem;
    }
    .card-pointcore {
      border: none;
      //border-bottom: solid 1px $gray-300 !important;
      .accordion-border {
        height: 1px;
        background: $gray-300;
        margin: 0 2.36rem;
      }
      &.open {
        transition: all 0.3s ease-in-out;
        box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.4);
        opacity: 1 !important;

        .accordion-border {
          background: $white;
        }
      }
    }
  }

  &__subtask_add_container {
    .form-footer__wrapper--open.form-footer__wrapper {
      background-color: #f5f4f7;
      border: 1px solid $gray-500;
      margin-bottom: 4rem;
      border-radius: 0;
      box-shadow: none;
      justify-content: space-between;
    }
  }

  &__add_button {
    margin: 4rem 2.36rem 1rem 2.36rem;
  }

  &__add_container {
    .form-footer__wrapper--open.form-footer__wrapper {
      padding: 2rem 2.36rem;
      margin-bottom: 4rem;
      border-radius: 0;
      justify-content: space-between;
    }
  }

  &__subtask_card {
    border: 1px solid $gray-500 !important;
    border-radius: 0;
    margin-bottom: 1.6rem;

    &_header {
      display: flex;
      justify-content: space-between;
      background-color: #f5f4f7;
      margin-left: 0;
      margin-right: 0;
      border-bottom: 1px solid #d7d8d9;
      padding: 2.1rem 2.1rem;

      &_container {
        display: flex;
      }
      &_check {
        margin-top: 0.4rem;
        margin-right: 2rem;
      }
      &_number {
        font-family: Interstate;
        font-size: $font-size-medium;
        font-weight: bold;
      }
      &_name {
        font-family: Interstate;
        font-size: $font-size-medium;
      }
      &_indicator {
        font-size: $font-size-medium;
      }
    }

    &_body {
      padding: 0 3.8rem 2rem 3.8rem !important;
    }
  }

  &__parent {
    &_header {
      font-family: 'Interstate';
      font-size: $font-size-large;
      cursor: pointer;
      background-color: $white;
    }
  }

  &__subtask {
    &_add_button span {
      color: $white;
      font-family: 'Forza Medium';
      font-size: $font-size-small;
      font-weight: 500;
      letter-spacing: 0.88px;
      line-height: $font-size-medium;
    }
    &_comments {
      margin-bottom: 2rem;

      &_data {
        display: flex;
      }
      &_container {
        padding-top: 2rem;
        padding-bottom: 2rem;
        border-bottom: 1px solid $gray-300;
        display: flex;
        justify-content: space-between;
      }
      &_thumbnail {
        height: 100%;
        padding: 5px 0;
        display: flex;
        span {
          margin-left: 1.8rem;
          margin-right: 1.8rem;
        }
      }

      &_name_date {
        display: flex;
      }
      &_name {
        font-family: 'Forza Medium';
        font-size: $font-size-medium;
        font-weight: bold;
        color: $primary;
        margin-right: 1.2rem;
      }
      &_date {
        font-family: Interstate;
        color: $gray-500;
        font-size: $small-font-size;
        margin-top: 4px;
      }
      &_text {
        opacity: 0.9;
        color: $gray-900;
        font-family: Interstate;
        font-size: $font-size-medium;
        line-height: 24px;
      }

      .user-thumbnail__initials {
        width: 5rem;
        height: 5rem;
        .user-thumbnail__img {
          width: 5rem;
          height: 5rem;
        }
      }
    }
  }
}
