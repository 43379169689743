.section {
  //margin-top: 30px;
  // margin-bottom: 30px;
  background-color: $light;
  height: 100%;
  border-radius: 6px;
  box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.4);

  &__body {
    padding: 2rem;
  }
}

%flex-section-header {
  display: flex;
  justify-content: center;
  align-items: center;
}

.section__header {
  @extend %flex-section-header, .m-0;
  width: 100%;
  border-radius: 6;
  border: 0;
  padding: 37px 26px 0px 26px;
  font-family: 'Vitesse Book';
  color: $dark;

  &__text {
    font-size: 2.4rem;
    @extend .p-0;
  }

  &__icon-wrapper {
    @extend %flex;
    padding: 0;
    margin: 0;
    flex-direction: column;
  }

  &__tab {
    padding: 12px 14px;
    font-size: $font-size-large;
  }

  &__icon-button {
    display: flex;
    padding: 12px 14px;
    align-items: center;
    font-size: 19px;

    > span {
      padding-left: 5px;
      font-family: 'Forza Medium';
      font-size: $font-size-large;
      font-weight: 500;
      color: $dark;
    }
  }
}
