.button__action {
  display: flex;
  justify-content: center;
  /* todo: padding is overridden by _button.scss*/
  padding: 13px 30px;
  font-size: 14px;

  &__icon {
    height: 2rem;
    width: 2rem;
    flex: 1;
    justify-content: center;
    align-items: center;
    background: none;
    border: none;
  }

  > span {
    padding-left: 5px;
    font-family: 'Forza Medium';
    font-size: $font-size-small;
    font-weight: 500;
    color: $dark;
    text-transform: uppercase;
  }

  &--primary {
    > span {
      color: $light;
    }
  }
}
//Used in form.js (server-next\components\Login)
