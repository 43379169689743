@import '~react-dates/lib/css/_datepicker.css';

.DateRangePicker {
  box-shadow: inset 0 1px 1px rgba($black, 0.075);
  border: 1px solid $gray-400;
  font-family: 'Interstate' !important;
  font-size: $font-size-small;
}

.DateInput {
  height: 100%;
  width: 105px;
}

.DateInput_input {
  font-family: 'Interstate' !important;
  font-size: $font-size-small;
  padding: 7px 10px;

  &__focused {
    outline: none;
    border: 2px solid $primary;
    border-radius: 2px;
  }
}

.SingleDatePickerInput__withBorder {
  border: 1px solid $dark;
  height: 42px;
}

.CalendarDay__selected,
.CalendarDay__selected_span {
  background: $primary;
  border: 1px solid $primary;
}

.SingleDatePickerInput__clearDate {
  margin: 0;
  height: 39px;
  right: 1px;
  background: #fff;

  &__default:hover {
    background: $gray-100;
    border-radius: 0;
  }
}
