.dashboard {
  &__image {
    background-position: center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    z-index: -1;
    position: absolute;
    height: 266px;
    width: 100%;
    left: 0;

    &-pointcore {
      background-position-y: 33%;
      background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
        url('/pointcore-dashboard.jpg');
    }

    &-core {
      background-position-y: 50%;
      background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
        url('/core-dashboard.jpg');
    }

    &-procedeo {
      background-image: url('/construction_bg3.jpg');
    }
  }

  &__section {
    width: 100%;
    justify-content: center;
    margin-top: 30px;
  }

  &__title {
    &--b {
      width: 100%;
      font-family: 'Vitesse Book';
      font-size: 3rem;
      color: black;
      margin-left: 82px;
    }
    &--w {
      width: 100%;
      font-family: 'Vitesse Book';
      font-size: 3rem;
      color: white;
      margin-left: 82px;
    }
  }

  &__container {
    // padding: 68px 86px;

    > .row {
      margin-bottom: 38px;
    }
  }
}

$x-large: 40vw;

.dashboard-cards {
  .card {
    margin: 1rem auto;
    min-height: 300px;
    min-width: 350px;
  }
}

@media (min-width: 576px) and (max-width: 1199.98px) {
  .page-header-card_container {
    .card {
      width: $x-large;
      height: $x-large * 0.75;
    }
  }
}
