.pie-chart {
  font-family: Arial;
  text-align: center;
  position: relative;
  width: 100%;
  height: 100%;

  &__total-value {
    text-align: center;
    // This is important to preserve the chart interactivity
    pointer-events: none;
  }
}
.overlay {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #000;
}

.overlay {
  &-xl {
    .totalLabel {
      font-size: 38px;
    }
    .centerTextLabel {
      font-size: 18px;
    }
  }
  &-lg {
    .totalLabel {
      font-size: 28px;
    }
    .centerTextLabel {
      font-size: 14px;
    }
  }
  &-md {
    .totalLabel {
      font-size: 18px;
    }
    .centerTextLabel {
      font-size: 8px;
    }
  }
  &-sm {
    .totalLabel {
      font-size: 12px;
    }
    .centerTextLabel {
      font-size: 6px;
      display: none;
    }
  }
}
.empty {
  .centerTextLabel {
    display: none;
  }
}
