$height: 11.25rem;

.page-nav {
  // TODO - Something like this for setting dynamic nav height
  // @media screen and (min-height: 9rem) {
  //   max-height: calc(100vh - #{$height});
  // }
  // @media screen and (max-height: 9rem) {
  //   max-height: 70vh;
  // }
  @extend .flex-column;
  display: flex;
  flex-wrap: nowrap;
  position: absolute;
  top: 64px + 58px;
  left: 0;
  min-height: 0;
  max-height: calc(100vh - #{$height});
  background-color: $gray-600;
  overflow: hidden;
  font-family: 'Forza Medium';
  font-weight: 500;
  width: 20rem;

  .jump-header {
    color: $light;
    font-size: $font-size-x-large;
    padding: 2rem 2.5rem;
    border-bottom: 1px solid $info;
    display: flex;
    justify-content: space-between;

    &__close {
      color: $light;

      button {
        background-color: transparent;
        border: none;
        color: white;
      }
      button:focus {
        outline: 0;
      }
    }
  }

  &.show .nav-item {
    display: inline;
  }
  &.hide .nav-item {
    display: none;
  }

  .nav-item {
    font-size: $font-size-small;
    border-radius: 0;
    border: 0;
    height: 4rem;
    display: flex !important;
    align-items: center;
    padding: 2.5rem 1rem;

    &:hover {
      background-color: $gray-900;
      border-left: 0.5rem solid $primary;
      padding-left: 0.5rem;
    }

    &.active {
      background-color: $gray-900;
      border-left: 0.5rem solid $primary;
      padding-left: 0.5rem;
    }

    > .nav-link {
      display: flex;
      align-items: center;
      color: $light;
      height: 100%;
      width: 100%;
      padding: {
        left: 2rem;
        top: 0;
        bottom: 0;
      }
    }
  }
}
