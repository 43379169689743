.project-team-members {
  &__container {
    max-height: 40rem;
    width: 100%;
    margin-bottom: 1rem;
  }

  & .expandable-card {
    &__header {
      padding-top: 17px;
      @extend .mb-3;
      &-content {
        width: 100%;
      }
    }
  }

  &__header {
    @extend .d-flex, .justify-content-between;
  }

  &__title {
    font-family: 'Vitesse Book';
    color: #191919;
    font-size: 2.4rem;
  }

  &__item {
    border: 1px solid transparent;
    &:hover {
      box-sizing: border-box;
      -moz-box-sizing: border-box;
      -webkit-box-sizing: border-box;
      border: 1px solid #6ca33c;
      border-radius: 4px;
      background-color: #ffffff;
      box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.4);
      transition: all 0.3s ease-in-out;
    }
  }

  &__empty {
    color: #4d4d4d;
    font-family: 'Forza Medium';
    font-size: 16px;
    letter-spacing: 1.33px;
    line-height: 19px;
    text-align: center;
    padding: 191px 0;
  }

  &__form {
    & .user-thumbnail__initials {
      width: 8rem;
      height: 8rem;
      font-size: 3rem;
    }
  }

  &__search {
    &-loading {
      color: #4d4d4d;
      font-family: 'Forza Medium';
      font-size: 16px;
      letter-spacing: 1.33px;
      line-height: 19px;
      text-align: center;
      padding: 20px 0;
    }
    &-subtitle {
      color: #4d4d4d;
      font-family: 'Forza Medium';
      font-size: 16px;
      letter-spacing: 1.33px;
      line-height: 19px;
      text-align: center;
      padding-top: 20px;
    }
  }
}
