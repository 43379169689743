@mixin svg-variant($color) {
  g {
    fill: $color;
    stroke: $color;
  }
}

@mixin svg-layer-variant($color) {
  g {
    .stroke {
      fill: $color;
    }
  }
}

/** Defines classes for every variant **/
@each $color, $value in $pointcore-colors {
  .svg-path-pointcore-#{$color} {
    @include svg-variant($value);
  }
  .svg-layered-pointcore-#{$color} {
    @include svg-layer-variant($value);
  }
}

/** Probably won't need different variants for separate companies
	The danger/warning colors will likely be the same
*/
@each $color, $value in $core-colors {
  .svg-path-core-#{$color} {
    @include svg-variant($value);
  }
  .svg-layered-core-#{$color} {
    @include svg-layer-variant($value);
  }
}

@each $color, $value in $procedeo-colors {
  .svg-path-procedeo-#{$color} {
    @include svg-variant($value);
  }
  .svg-layered-procedeo-#{$color} {
    @include svg-layer-variant($value);
  }
}
