.summary {
  // margin: 0 3rem 3rem 3rem;
  .row {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #bbbdc0;
  }
  .row:last-child {
    border-bottom: none;
  }
  &__description {
    font-family: 'Interstate';
    font-size: $font-size-medium;
    line-height: 28px;
    padding: 0 30px 30px 30px;
  }
  &__subheader {
    font-family: 'Interstate';
    font-size: $font-size-x-large;
    letter-spacing: 0;
    line-height: 22px;
    padding: 15px 0;
  }
  &__data {
    font-family: 'Forza Medium';
    font-size: $font-size-x-large;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 22px;
    padding: 15px 5px 15px 5px;
  }
  &__ &__field {
    display: flex;
    flex-direction: row;
    min-height: 20rem;
    &__input {
      font-size: $font-size-x-small;
    }
    justify-content: space-between;
    &__row {
      margin: auto;
      margin-bottom: $font-size-small;
      height: $font-size-medium * 2;
    }
    &__subheader {
      flex: 1;
      flex-direction: row;
      font-weight: bold;
      justify-content: space-between;
    }
  }
}
