.user-thumbnail {
  &__initials {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0;
    padding: 0;
    width: 4rem;
    height: 4rem;
    min-width: 4rem;
    float: left;
    overflow: hidden;
    border-radius: 50%;
  }
  &__img {
    width: 60px;
    height: 60px;
    border-radius: 50%;
  }
}

// #region: pointcore theme
.badge-pointcore {
  @extend .badge;
}

@each $color, $value in $pointcore-colors {
  .badge-pointcore-#{$color} {
    @include button-variant($value, $value);
  }
}

@each $color, $value in $pointcore-colors {
  .badge-pointcore-outline-#{$color} {
    @include button-outline-variant($value);
  }
}
// #endregion pointcore theme

// #region core theme
.badge-core {
  @extend .badge;
}

@each $color, $value in $core-colors {
  .badge-core-#{$color} {
    @include button-variant($value, $value);
  }
}

@each $color, $value in $core-colors {
  .badge-core-outline-#{$color} {
    @include button-outline-variant($value);
  }
}
// #endregion core theme

// #region procedeo theme
.badge-procedeo {
  @extend .badge;
}

@each $color, $value in $procedeo-colors {
  .badge-procedeo-#{$color} {
    @include button-variant($value, $value);
  }
}

@each $color, $value in $procedeo-colors {
  .badge-procedeo-outline-#{$color} {
    @include button-outline-variant($value);
  }
}
// #endregion procedeo theme
