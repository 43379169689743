.search-items {
  &__form {
    width: 100%;
    margin-top: auto;
    margin-bottom: auto;
    padding: 0px 20px;
  }
  &__title-container {
    display: flex;
    justify-content: space-between;
  }

  &__list-container {
    width: 100%;
    max-height: 30rem;
    z-index: 900;
    overflow-y: scroll;
    outline: none;
    // border: 1px solid $primary;
  }
  &__list-title {
    color: #1a1a1a;
    font-family: 'Interstate';
    font-size: $font-size-small;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 17px;
    padding: 2rem 2rem 0rem 0.75rem;
  }
  &__list-icon {
    color: #008348;
    cursor: pointer;
  }
  &__list-item {
    font-family: 'Interstate';
    font-size: $font-size-small;
    color: $gray-700;
    display: flex;
    justify-content: space-between;
    padding: 2rem 1rem !important;
  }
  &__hr {
    border: 1px solid #191919;
  }
  &__search-icon {
    position: absolute;
    margin: 11px 10px 10px 12px;
    font-size: 2rem;
  }
}
.table td {
  border-top: none;
}
