.comment-component {
  &__wrapper {
    border-bottom: 1px solid #bbbdc0;
    width: 100%;
    display: flex;
    align-items: flex-start;
  }

  &__box {
    flex: 1;
    margin-bottom: 26px;
    margin-top: 10px;
    margin-left: 13px;
  }

  &__user-thumbnail {
    display: flex;
    margin-bottom: 26px;
    justify-content: center;
  }
  &__user-comment {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
  &__user-name {
    font-family: 'Forza Medium';
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 19px;
    text-align: start;
    margin-right: 20px;
    display: block;
  }
  &__timestamp {
    color: #4d4d4d;
    font-family: 'Interstate';
    font-size: 12px;
    letter-spacing: 0;
    line-height: 14px;
    min-width: fit-content;
  }
  &__comment-text {
    opacity: 0.9;
    color: #191919;
    font-family: 'Interstate';
    font-size: 16px;
    letter-spacing: 0;
    line-height: 24px;
  }
  &__file-thumbnail {
    height: 51px;
    width: 68px;
    border-radius: 2px;
    margin: 6px;
    cursor: pointer;
  }
  &__file-section {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
  &__file-list {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    overflow-x: auto;
    scroll-behavior: smooth;
  }
  &__vertical-rule {
    box-sizing: border-box;
    height: 61px;
    width: 1px;
    margin: 0 7px;
    border: 1px solid $gray-300;
    &--small {
      height: 36px;
    }
  }
  &__action-buttons {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
  &__icon-button {
    outline: none;
    border: none;
    background-color: inherit;
    margin: 0;
    padding: 0;
    border: none;
  }
  &__icon {
    display: flex;
    color: #4d4d4d;
    margin: 3px;
    cursor: pointer;
  }
}
