%flex-list-item {
  display: flex;
  align-items: center;
  flex-direction: row;
}

.list-item {
  &__li {
    @extend %flex-list-item;
    justify-content: space-between;
    margin: 15px;
    min-height: 20px;
    height: contain;
  }
  &__wrapper {
    @extend %flex;
    justify-content: flex-start;
    max-width: 90%;
  }

  &__text {
    margin-left: 10px;
  }

  &__icon {
    padding-top: 0;
    padding-bottom: 0;
    &--open {
      color: $danger;
      &:hover {
        color: darken($danger, 5%);
      }
    }
    &--closed {
      color: $primary;
    }
  }

  &__separator {
    margin: 0 30px 0 40px;
  }
}
