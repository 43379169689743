.tile-component {
  &__header {
    color: $black;
    font-family: 'Vitesse Book';
    font-size: 25px;
    font-weight: bold;
  }

  &__card-body {
    display: flex;
  }

  &__card-data_cards {
    width: 25%;
  }

  &__card-chart {
    width: 75%;
    padding-left: 30px;
    .chartjs-render-monitor {
      margin-top: 30px;
    }
    .chart__title {
      line-height: 2rem;
    }
  }

  &__card {
    border: solid 2px #ced0d2;
    padding: 40px 20px;
    margin-bottom: 20px;

    &--md {
      width: 30rem;
      max-height: 12.2rem;
      margin: auto;
    }
    &-title {
      color: $gray-400;
      font-family: 'Vitesse Book';
      font-size: 16px;
      font-weight: bold;
      text-align: center;
      margin-bottom: 15px;
    }
    &-date {
      color: #191919;
      font-family: 'Forza Medium';
      font-size: 20px;
      font-weight: 500;
      letter-spacing: 0;
      text-align: center;
    }
    &-remaining {
      color: #9c9c9c;
      font-family: Interstate;
      font-size: 14px;
      letter-spacing: 0;
      text-align: center;
    }
  }
  &__chart-container {
    height: 330px !important;
  }
  &__chart-title {
    font-size: 2rem;
    line-height: 2rem;
    font-family: 'Vitesse Book';
    color: #353535;
    font-weight: bold;
    margin-bottom: 40px;
    padding-left: 25px;
  }
}
