.aggrid {
  width: 100%;
  &__disabled {
    height: 100%;
    opacity: 50%;
    background-color: $info;
    color: $light;
    font-size: $font-size-large;
  }
}
