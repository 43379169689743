.table {
  width: 100%;
  margin-bottom: 0;
  padding-left: 25px;
  padding-right: 25px;
  thead {
    border-bottom: solid 2px #252525;
  }

  th {
    border-top: none;
    color: #252525;
    font-family: Interstate;
    font-size: $font-size-small;
    letter-spacing: 0;
    line-height: 30px;
    height: 40px;
    font-weight: bold;
  }

  tr {
    border-bottom: solid 1px #c1c1c2;
  }

  &__header {
    &-sm {
      width: 7rem;
    }
    &-md {
      width: 11rem;
    }
    &-lg {
      width: 16rem;
    }
    &-xlg {
      width: 35rem;
    }
    &-fill {
      width: 100%;
    }
  }
  &__data {
    color: #252525;
    font-family: Interstate;
    font-size: $font-size-small;
    letter-spacing: 0;
    line-height: 4.3rem;
    text-overflow: ellipsis;
    max-width: 28rem;
    overflow: hidden;
    white-space: nowrap;
  }
  @each $company, $color in $primary-colors {
    &-#{$company} {
      &__ellipsis {
        max-width: 7rem;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        text-decoration-color: $color;
      }
    }
  }
  &__small {
    color: #4d4d4d;
    font-family: Interstate;
    font-size: 12px;
    text-overflow: ellipsis;
    overflow: hidden;
    letter-spacing: 0;
  }
  &__empty {
    color: #4d4d4d;
    font-family: 'Forza Medium';
    font-size: $font-size-medium;
    font-weight: 500;
    letter-spacing: 1.33px;
    line-height: 19px;
    text-align: center;
  }
  &__actions {
    text-align: right;
  }
}

.responsive-table {
  display: flex;
  flex-wrap: wrap;
  margin: 0;
  padding: 0;

  &--5cols > &__row > &__cell {
    width: 20%;
  }

  &__row {
    display: flex;
    margin: 0;
    padding: 0 25px;
    width: 100%;
  }

  &__cell {
    &--head {
      border-top: none;
      border-bottom: solid 2px $gray-300;
      color: $gray-700;
      font-family: 'Interstate';
      font-size: $font-size-small;
      letter-spacing: 0;
      line-height: 30px;
      font-weight: bold;
      height: 45px;
    }

    &--alignTop,
    &--alignMiddle,
    &--alignBottom {
      display: flex;
    }
    &--alignTop {
      align-items: flex-start;
    }
    &--alignMiddle {
      align-items: center;
    }
    &--alignBottom {
      align-items: flex-end;
    }
    &--alignLeft {
      text-align: left;
    }
    &--alignCenter {
      text-align: center;
    }
    &--alignRight {
      text-align: right;
    }

    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    height: 58px;
    box-sizing: border-box;
    flex-grow: 1;
    width: 100%;
    padding: 0.8em 1.2em;
    overflow: hidden;
    list-style: none;
    border-bottom: 1px solid $gray-300;
    color: $gray-700;
    font-family: 'Interstate';
    font-size: $font-size-small;
    // letter-spacing: 1.33px;
    > h1,
    > h2,
    > h3,
    > h4,
    > h5,
    > h6 {
      margin: 0;
    }
  }
}
