.project-grid {
  &__text {
    height: 2.1rem;
    font-family: 'Vitesse Book' !important;
    font-size: $font-size-large;
    letter-spacing: 0;
    line-height: 2.1rem;
    @each $company, $color in $primary-colors {
      &--highlight-#{$company} {
        color: $color;
        font-weight: bold;
      }
    }
  }

  &__button {
    background: none;
    border: none;
    margin-left: 1.3rem;
    &:focus {
      outline: none;
    }
    &:hover {
      background-color: $light;
    }
    @each $company, $color in $primary-colors {
      &--text-#{$company} {
        text-decoration: none;
        height: 1.6rem;
        color: #191919;
        font-family: 'Forza Medium';
        font-size: $font-size-small;
        font-weight: 500;
        letter-spacing: 0.88px;
        line-height: 16px;
        border-bottom: 1px solid $color;
      }
    }
  }
}
