.integer-layout {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: 'Forza Medium';
  font-weight: 500;
  letter-spacing: 0;
  @each $company, $color in $primary-colors {
    &--#{$company} {
      color: $color;
    }
  }
}
