.construction-project-team-members {
  &__container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: stretch;
    align-items: flex-start;
  }
}
