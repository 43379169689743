// navigation button
.button__navigation {
  &-group {
    width: fit-content;

    &:hover {
      > button:not(:hover) {
        opacity: 0.5;
      }
    }
  }
}
//used in the index.js file (server-next\components\permissions\navigation)
