.expandable-card {
  &__container {
    min-height: 80px;
    width: 100%;
    height: 100%;
    margin-bottom: 3rem;
    padding-top: 2rem;
    border-radius: 6px;
    background-color: $white;
    box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.4);
    background-color: $white;
  }
  &__detail {
    &--row {
      display: flex;
      width: 100%;
    }
    &--subtitle {
      color: #212121;
      font-family: 'Vitesse Book';
      font-size: $font-size-large;
      font-weight: 500;
      letter-spacing: 0;
      line-height: 22px;
      margin-bottom: 23px;
    }
  }

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 40px;
    padding: 0 3rem;
    &__container {
      display: flex;
      align-items: center;
      div {
        margin-right: 20px;
      }
      &--icon {
        height: 20px;
        line-height: 20px;
        font-size: 15px;
        margin-top: 6px;
      }
    }
    &-icon {
      height: 100%;
      text-align: center;
      padding-left: 50%;
      .icon {
        color: #4d4d4d;
        font-size: 24px;
        line-height: 37px;
        position: relative;
        top: 50%;
        -ms-transform: translateY(-50%);
        transform: translateY(-50%);
      }
    }
  }
  &__body {
    padding-top: 20px;
  }
}
