.cost-layout {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: 'Forza Medium';
  font-weight: 500;
  letter-spacing: 0;
  &__item {
    display: inherit;
    width: inherit;
    text-align: center;
    font-size: $font-size-x-large;
    align-items: center;
    justify-content: center;
    &__name {
      color: $gray-900;
      margin-right: 12px;
    }
  }
}
