.construction-project-info {
  &__container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
  }
  &__top {
    max-height: 425px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: stretch;
  }
  &__bottom {
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    align-items: stretch;
  }
  &__section {
    height: 100%;
    width: 100%;
    padding: 8px;
  }
  &__header {
    font-family: 'Vitesse Book';
    color: $gray-800;
    font-weight: bold;
    line-height: 29px;
  }
  &__description {
    width: 100%;
    color: $gray-900;
    font-family: Interstate;
    font-size: $font-size-medium;
    letter-spacing: 0;
    max-height: 400px;
    overflow-y: auto;
    line-height: 28px;
  }
  &__map {
    flex: 1;
    height: 425px;
    position: relative;
  }
  &__table {
    width: 100%;
    margin: 0;
    padding: 0;
    height: 100%;

    &__row {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 10px 0;

      &--bordered {
        border-bottom: 1px solid $gray-300;
      }
      &__title {
        font-family: 'Interstate';
        font-size: $font-size-large;
        letter-spacing: -0.18px;
        line-height: 22px;
      }
      &__data {
        font-family: 'Forza Medium';
        font-size: $font-size-x-large;
        text-align: end;
      }
    }
    &__rowSpacer {
      height: 1px;
      width: 100%;
      border: 1px solid $gray-300;
      margin: 10px 0;
    }
  }
}
