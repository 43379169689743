.settings-form {
  &__container {
    padding: 0px 10px 0px 20px;
  }
  &__help-text {
    font-family: 'Interstate';
    letter-spacing: 0;
    line-height: 19px;
    color: $gray-400;
  }
  &__required {
    color: red;
  }
}
