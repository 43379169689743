.lightbox {
  &__wrapper {
    .ril__indicators li {
      width: 16px;
      height: 16px;
    }
    &__hide {
      .ril__toolbar {
        background-color: transparent !important;
      }
    }
  }
}
