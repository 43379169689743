.comment-editor {
  &__wrapper {
    box-sizing: border-box;
    height: 165px;
    width: 100%;
    border: 1px solid #bbbdc0;
    background-color: #ffffff;
    box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.4);
    margin: 0 10px;
    padding: 0;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
  }

  &__box {
    flex: 1;
    padding: 0;
  }

  &__user-thumbnail {
    display: flex;
    margin-bottom: 60px;
    justify-content: center;
  }
  &__user-name {
    font-family: 'Forza Medium';
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 19px;
    text-align: start;
    margin-right: 20px;
  }
  &__timestamp {
    color: #4d4d4d;
    font-family: 'Interstate';
    font-size: 12px;
    letter-spacing: 0;
    min-width: fit-content;
    line-height: 14px;
    margin-top: 3px;
  }
  &__buttons {
    font-family: 'Forza Medium';
    font-size: 14px;
    outline: none;
    font-weight: 500;
    letter-spacing: 0.88;
    line-height: 16px;
    text-decoration: underline;
    margin-bottom: 5px;
  }
  &__inner-textarea {
    border-radius: 6px;
    color: #000000;
    font-family: 'Interstate';
    font-size: 14px;
    letter-spacing: 0;
    line-height: 20px;
    height: 110px;
    outline: 0;
    border: 1px solid #008348;
    resize: none;
  }
  &__inner-textarea.form-control {
    border-radius: 6px;
  }
  &__inner-textarea:focus {
    outline: 0;
    border: 1px solid #008348;
    box-shadow: 0 0 5px 0 #008348;
  }
  &__file-thumbnail {
    height: 51px;
    width: 68px;
    border-radius: 2px;
    margin: 6px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    color: $gray-900;
    position: relative;
    &--empty {
      background-color: $gray-100;
    }
    &__ext {
      font-size: $font-size-small;
      line-height: 15px;
      text-align: center;
      font-family: 'Forza Medium';
    }
    &__icon {
      color: $gray-900;
    }
    &__exit-btn {
      outline: none;
      border: none;
      background-color: transparent;
      position: absolute;
      top: -5px;
      right: -3px;
      padding: 0;
    }
    &__exit-icon {
      color: $red;
    }
  }
  &__vertical-rule {
    box-sizing: border-box;
    height: 36px;
    width: 1px;
    margin: 0 7px;
    border: 1px solid #bbbdc0;
  }
  &__action-buttons {
    width: 100%;
    display: flex;
    align-items: center;
    overflow-x: auto;
    scroll-behavior: smooth;
  }
  &__input {
    display: none;
  }
  &__file-actions {
    flex: 1;
    display: flex;
    align-items: center;
  }
  &__user-comment {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
  &__icon {
    height: 19px;
    color: #4d4d4d;
    cursor: pointer;
  }
}
