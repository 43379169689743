%flex-phase-summary {
  display: flex;
  align-items: center;
}

.phase-summary-group {
  display: grid;
  grid-template-columns: repeat(5, 19%);
  column-gap: 1%;
  margin: {
    top: 2rem;
    left: 0;
    right: 0;
    bottom: 7rem;
  }
}

.phase-summary {
  max-width: 30rem;
  margin: 1rem 0;

  .card-header {
    background-color: $light;
    border-bottom: none;
    padding: 2rem;
  }
  &-card {
    @extend .card;
    //background-color: $dark-gray;
    border-radius: 6px !important;
    box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.4);
    border: 0;

    &__disabled {
      opacity: 0.5;
      background-color: $info;
      color: $light;
      font-size: $font-size-large;
    }

    &__header {
      color: $light;
      padding: {
        bottom: 2rem;
      }
      .button__restore-default {
        padding: 0;
      }
    }

    &__title {
      font-size: $font-size-x-large;
      font-family: 'Vitesse Book';
      color: $gray-800;
      font-weight: bold;
    }

    &__aggregates {
      border: 1px solid $gray-500;
      padding: 20px;
    }

    &__view-all-link {
      @extend %flex-phase-summary;
      font-size: $font-size-small;
      padding: 0;
      background-color: unset !important;
      border: 0;

      > * {
        margin-left: 0.5rem;
      }
    }

    &__alert-table_container {
      padding: 1.4rem 0rem 1rem 0rem;
      table {
        font-family: 'Interstate';
        width: 100%;
        margin-bottom: 1rem;
        &:hover tr {
          opacity: 0.5;
          transition: opacity 0.2s;
        }

        &:hover thead {
          border-bottom: 1.5px solid $gray-300;
          transition: border 0.2s;
        }

        thead {
          font-weight: bold;
          font-size: $font-size-small;
          color: $gray-800;
          transition: border 0.2s;
          border-bottom: 1.5px solid $gray-900;

          tr {
            cursor: default;
          }

          th.data {
            text-align: left;
            padding-bottom: 1.5rem;
            padding-left: 2rem;
          }
          th {
            text-align: center;
          }
          th.last {
            padding-right: 2rem;
          }
        }

        tbody {
          tr {
            border-bottom: 1.5px solid $gray-300;
            transition: opacity 0.3s;
          }
          tr:last-child {
            border-bottom: none;
          }
          tr:hover {
            box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.4);
            cursor: pointer;
            opacity: 1;
          }

          td {
            font-family: 'Interstate';
            font-size: $font-size-small;
            padding: 1rem 0;
            text-align: right;
          }
          td.indicator {
            width: 21px;
          }
          td.data {
            text-align: left;
            padding-left: 2rem;
          }
          td.last {
            padding-right: 2rem;
            width: 4rem;
          }
        }
      }
      .action-button {
        margin-bottom: 3rem;
        margin-left: 2rem;
        margin-right: 2rem;
        width: -moz-available;
        width: -webkit-fill-available;
        width: stretch;
      }
    }

    &__indicator-container {
      width: 100%;
      height: 20px;
      padding-top: 1rem;
    }

    &__indicator {
      height: 10px;
      width: 10px;
      margin: 0 auto;
      border-radius: 5px;

      &.indicator_safe {
        background-color: $green;
      }
      &.indicator_danger {
        background-color: $red;
      }
      &.indicator_alert {
        background-color: $yellow;
      }
    }

    &__aggregate {
      @extend %flex-phase-summary;
      flex-direction: column;
      color: $gray-400;

      &-label {
        font-size: $font-size-medium;
        font-weight: 500;
        font-family: 'Vitesse Book';
      }

      &-value {
        font-size: $font-size-x-large;
        font-family: 'Forza Medium';
        color: $gray-900;
      }

      &-error {
        font-size: $font-size-small;
        font-family: 'Forza Medium';
        color: $gray-900;
      }
    }

    &__divider {
      background-color: $gray-500;
      height: 1px;
      margin: 20px 0;
    }
  }

  &__alert-list {
    @extend %flex-phase-summary;

    &-empty-button {
      font-size: $font-size-medium;
      padding: 2rem;

      &:hover {
        background-color: unset !important;
        color: unset !important;
      }
    }

    &--space {
      justify-content: space-between;
      width: 100%;
    }

    &--empty {
      @extend %flex-phase-summary;
      height: 100%;
      width: 100%;
      justify-content: space-around;
      background-color: #fff;
      color: $dark;
      font-size: $font-size-large;
    }
  }

  &__alert-item {
    @extend %flex-phase-summary;
    justify-content: space-between;
    border: 0;
    border-bottom: 1px;
    border-style: solid;
    border-color: #ddd;
    width: 100%;
    padding: 2rem;
    border-radius: 0;

    &-title {
      font-size: $font-size-medium;
      text-align: left;
      overflow: wrap;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
    &-contract {
      font-size: $font-size-small;
      text-align: left;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    &-icons {
      @extend %flex-phase-summary;
      justify-content: space-evenly;
    }

    &-icon {
      color: $danger;
      padding: {
        right: 1rem;
      }
    }

    &:last-child {
      border: 0;
      border-bottom-left-radius: 6px;
      border-bottom-right-radius: 6px;
    }
  }

  &__see-more-button {
    > button {
      border-radius: 0 !important;
    }
  }

  .card-header {
    border-radius: 6px;
  }

  > .card-body {
    @extend %flex-phase-summary;
    justify-content: space-around;
  }
}
