.tile {
  &__container {
    border: solid 1px #4d4d4d;
  }

  &__box {
    height: 13rem !important;
    width: 100%;
    padding: 30px;
  }

  &__label {
    font-family: 'Forza Medium';
    color: $gray-500;
    font-size: $font-size-medium;
    font-weight: 700;
    letter-spacing: 0;
    text-align: center;
    white-space: nowrap;
  }

  &__value {
    font-family: 'Forza Medium';
    font-size: $h1-font-size;
    font-weight: 500;
    color: #191919;
    letter-spacing: 0;
    line-height: 6rem;
    text-align: center;
  }
}
