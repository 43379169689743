@mixin text-variant($color) {
  color: $color;
}

// #region pointcore theme
@each $color, $value in $pointcore-colors {
  .text-pointcore-#{$color} {
    @include text-variant($value);
  }
}
// #endregion pointcore theme

// #region core theme
@each $color, $value in $core-colors {
  .text-core-#{$color} {
    @include text-variant($value);
  }
}
// #endregion core theme

// #region procedeo theme
@each $color, $value in $procedeo-colors {
  .text-procedeo-#{$color} {
    @include text-variant($value);
  }
}
// #endregion procedeo theme
