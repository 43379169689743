.custom-legend {
  width: 100%;
  height: 80%;
  max-height: 280px;
  overflow-y: hidden;
  &:hover {
    overflow-y: overlay;
  }

  &--side {
    margin-top: 36px;
  }

  &--bottom {
    margin: 0;
  }

  &__list {
    > li {
      margin-top: 10px;
    }
  }
  &__color {
    height: 10px;
    width: 10px;
    display: inline-block;
    border-radius: 50%;
    margin: 0 5px;
  }
  &__label {
    font-family: Interstate;
    font-size: $font-size-small;
    color: $gray-500;
    letter-spacing: 0;
    line-height: 18px;
  }
  &__value {
    margin: 0 5px;
    font-family: Interstate;
    font-size: $font-size-small;
    color: $gray-900;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 18px;
  }
}
