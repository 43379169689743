.user-settings {
  &__grid {
    &-container {
      width: 100%;

      .edit-icon {
        color: $primary;
      }
      .ag-center-cols-container .ag-react-container {
        font-size: $font-size-medium;
      }
    }
    &_user {
      display: table;

      &_photo {
        display: table-cell;
        vertical-align: middle;
        padding-right: 1.5rem;
      }
      &_name {
        font-size: $font-size-medium;
      }
    }
  }
}
