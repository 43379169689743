.confirm-btn {
  border-radius: 2px;
  font-family: 'Forza Medium';
  padding: 13px 15px;
  height: 50px;
  margin: {
    left: 10px;
    right: 10px;
  }
  &--save {
    display: flex;
    align-items: center;
  }
  &__icon {
    height: 16.71px;
    width: 16.71px;
    color: $white;
    margin-right: 5px;
  }
}

//TODO: remove after refactor/phase2.1/cards branch is merged
