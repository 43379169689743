.search-filter {
  width: 200px;
  & .form-control {
    padding-left: 4.2rem;
    height: 42px;
    font-size: $font-size-small;
  }
  & ::placeholder {
    color: $gray-500;
    opacity: 1;
  }
  &__icon {
    position: absolute;
    z-index: 2;
    display: block;
    height: 1.9rem;
    text-align: center;
    pointer-events: none;
    color: $black;
    margin: 11px 10px 12px 14px;
  }
}
