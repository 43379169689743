.aggregate-card {
  &__card {
    &--no-shadow {
      box-shadow: none !important;
      border-radius: 0;
      background-color: $light;
      padding: 22px 0;
    }
    align-items: center;
    justify-content: space-evenly;
    text-align: center;
    height: 133px;
    width: 100%;
    padding: 30px;
  }

  &__title {
    font-size: $font-size-medium;
    font-family: 'Forza Medium';
    font-weight: 700;
    color: black;
  }

  &__subtitle {
    font-size: $font-size-xx-large;
    font-family: 'Forza Medium';
    color: $primary;
    font-weight: 500;
  }

  &__filter {
    margin-right: 2rem;
    width: 100%;
  }

  &__header {
    color: $gray-800;
    font-family: 'Vitesse Book';
    font-size: 24px;
    font-weight: 700;
    letter-spacing: 0;
    margin-left: 1.5rem;
    margin-right: 13px;
  }

  &__wrapper {
    display: flex;
    margin-top: 2rem;
    margin-bottom: 2rem;
    text-align: center;
    justify-content: space-between;
  }

  &__tile {
    margin-right: 2rem;
    width: 100%;
  }
}
