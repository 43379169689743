.comments-component {
  &__card {
    min-height: 734px;
    width: 1268px;
    border-radius: 6px;
    display: flex;
    box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.4);
  }

  &__box {
    display: flex;
    flex-flow: row wrap;
    scroll-behavior: smooth;
  }
  &__files {
    width: 100%;
  }
  &__title {
    color: #1e1e1e;
    font-family: 'Forza Medium';
    font-size: 24px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 29px;
    padding: 20px;
  }
  &__wrapper {
    padding-left: 20px;
    padding-top: 17px;
    width: 100%;
    &.editing {
      padding-top: 0;
    }
  }
  &__list {
    margin-top: 25px;
  }
}
