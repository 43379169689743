%flex {
  display: flex;
  align-items: center;
}

.comment {
  &__wrapper {
    @extend %flex;
    border-radius: 5px !important;
    box-shadow: 1px 1px 10px 1px rgba(0, 0, 0, 0.25) !important;
    flex-direction: row;
    justify-content: center;
    margin: 0.5rem auto 0.5rem auto;
    padding: 1rem;
    width: 90%;
  }
  &__user-info {
    @extend %flex;
    flex-direction: column !important;
    justify-content: center;
    align-items: flex-start !important;
  }
  &__delete-btn {
    background-color: $light;
    border: none;
    color: $danger;
    margin: 0;
    outline: none;
  }
}
