.form-footer {
  &__wrapper {
    width: 100%;
    height: 109px !important;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    padding: 20px 15px 15px 0px;
    &--open {
      border-radius: 0 0 6px 6px;
      box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.4);
      padding: 20px;
    }
  }
  &__btn-wrapper {
    margin: 5px;
    height: 42px;
    width: 120px;
    display: flex;
    align-items: center;
    justify-content: space-around;
  }
  &__top-hr {
    position: absolute;
  }
  &__open-btn,
  &__open-btn-sm {
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    white-space: nowrap;
    border-radius: 2px;
    font-family: 'Forza Medium';
    text-transform: uppercase;
  }
  &__open-btn-sm {
    height: 4.2rem;
    width: 16.5rem;
    font-size: $font-size-small;
  }
  &__open-btn {
    height: 42px;
    width: 247px;
  }
  &__btn-icon {
    height: 17.28px;
    width: 17.28px;
    color: $white;
    margin-right: 5px;
  }
  &__add-btn {
    padding: 12px;
    font-size: 1.4rem;
  }
  &__clear-btn {
    outline: none;
    padding: 0;
    margin: 0;
    border: none;
    background-color: inherit;
  }
  &__cancel-icon {
    height: 25px;
    width: 25px;
    color: $gray-500;
    display: block;
    margin: 10px;
  }
  &__label--force,
  textarea:not(:placeholder-shown) + &__label,
  textarea:focus + &__label,
  input:not(:placeholder-shown) + &__label,
  input:focus + &__label {
    position: absolute;
    top: -10px;
    left: 5px;
    padding: 0 3px;
    color: $black;
    background-color: $white;
    font-family: 'Interstate';
    font-size: 10px;
    letter-spacing: 0;
    line-height: 20px;
    height: auto;
    width: auto;
  }
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  input[type='number'] {
    -moz-appearance: textfield;
  }
  &__user {
    font-family: 'Interstate';
    font-size: 1.4rem;
    margin-left: 1em;
    line-height: 1.4rem;
    white-space: nowrap;
  }
  &__label {
    transition: 0.1s;
    position: absolute;
    pointer-events: none;
    cursor: default;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    padding: 0 11px;
    color: $black;
    background-color: none;
    font-family: 'Interstate';
    font-size: $font-size-small;
    letter-spacing: 0;
    line-height: 20px;
    display: flex;
    align-items: center;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  &__input-group {
    position: relative;
    // margin: 10px 10px;
    height: 42px;
  }
  &__input {
    &--xsm {
      width: 9.1rem;
    }
    &--sm {
      width: 14.1rem;
    }
    &--md {
      width: 16.7rem;
    }
    &--lg {
      width: 41.6rem;
    }
    &--xlg {
      width: 61.2rem;
    }

    &--fill {
      width: 100%;
    }
  }
  &__ratings-wrapper {
    align-items: center;
    height: 100%;
    display: flex;
    padding-top: 5px;
    justify-content: center;
  }
}
