.modal {
  &-backdrop {
    background-color: $dark;
    opacity: 0.8;
  }

  &-content {
    border-radius: 6px;
    border: 0;
    box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.4);
  }

  &-title {
    font-family: 'Vitesse Book';
    padding: 5px 0px 5px 15px;
  }
  &__help-text {
    font-family: 'Interstate';
    font-size: $font-size-small;
    letter-spacing: 0;
    line-height: 19px;
    color: $gray-400;
  }
  &__required {
     font-size: $font-size-small;
    margin-left: 20px;
    color: red;
  }
  &-footer {
    height: 93px;
    width: 100%;
    border-radius: 0 0 6px 6px;
    padding: 20px;
  }
  .modal-header {
    border-bottom: 0 none;
  }

  .modal-footer {
    border-top: 0 none;
  }

  &--add-photo {
    width: 635px;
  }
}
