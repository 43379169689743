$base-padding: 3rem;

@each $company in $companies {
  .btn-#{$company} {
    @extend .btn;
    font-size: $font-size-medium;
    padding: calc(#{$base-padding} * 0.25) $base-padding;
  }

  .btn-#{$company}-sm {
    @extend .btn-sm;
    font-size: $font-size-small;
    $padding: $base-padding * (1 / 3);
    padding: calc(#{$padding} * 0.25) $padding;
  }

  .btn-#{$company}-lg {
    @extend .btn-lg;
    font-size: $font-size-large;
    $padding: $base-padding * 2;
    padding: calc(#{$padding} * 0.25) $padding;
  }

  @if $company == 'pointcore' {
    // this is an override for pointcore's font color on primary btn
    .btn-#{$company}-primary {
      color: #fff !important;
    }
  }

  .btn-#{$company}-outline-dark {
    &:hover {
      > span {
        color: $white;
      }
    }
  }

  $colors: company-colors($company);

  $company-primary: map-get($colors, 'primary');

  @each $color, $value in $colors {
    .btn-#{$company}-#{$color} {
      @include button-variant($value, $value);
    }
  }

  @each $color, $value in $colors {
    .btn-#{$company}-outline-#{$color} {
      @include button-outline-variant($value);
    }
  }

  .btn-#{$company}-block {
    @extend .btn-block;
  }

  .btn-#{$company}-link {
    @include button-link($company-primary);

    &:disabled {
      opacity: 0.5;
    }
  }
}

.btn-toolbar {
  width: 100%;
}

.button__group {
  display: flex;

  > button {
    ~ button {
      margin-left: 14px;
    }
  }
}